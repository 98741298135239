import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { ValidationFormOtherInfoEdit } from '@app/components/manage-other-info/ValidationFormEdit/ValidationForm';

const EditOtherInfo: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <PageTitle>Manage OtherInfo</PageTitle>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4 style={{ fontWeight: 'bold' }}>Edit OtherInfo</h4>
        <BaseButton type="primary" onClick={() => navigate(-1)}>Back</BaseButton>

      </div>
      < ValidationFormOtherInfoEdit />
      <hr style={{ marginTop: '30px' }} />
      <div style={{ marginTop: '50px' }}>
  
      </div>
    </>
  );
};

export default EditOtherInfo;
