import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import MyFacilitiesComponent from '@app/components/manage-facilities/list';
import MyMedicinesComponent from '@app/components/manage-medicines/list';

const ManageMedicine: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>Manage Medicine</PageTitle>
      <MyMedicinesComponent />
    </>
  );
};

export default ManageMedicine;
