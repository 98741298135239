import userReducer from '@app/store/slices/userSlice';
import appointmentsReducer from '@app/store/slices/appointmentSlice';
import authReducer from '@app/store/slices/authSlice';

import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import  patientReducer  from '@app/store/slices/patientDataSlice';

export default {
  user: userReducer,
  auth: authReducer,
  patient:patientReducer,
  appointments:appointmentsReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer
};
