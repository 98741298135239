import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityStoryItem } from './ActivityStoryItem/ActivityStoryItem';
import { Stats, getStatisticsExternal } from '@app/api/stats.api';
import * as S from './ActivityStory.styles';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

export const StatsSection: React.FC<any> = (props) => {
  const [story, setStory] = useState<any[]>(props?.companyDashDetails?.stats);

  const { t } = useTranslation();

  // useEffect(() => {
  //   getStatisticsExternal().then((res) => setStory(res));
  // }, []);

  useEffect(() => {
    setStory(props?.companyDashDetails?.stats);
  }, [props.companyDashDetails])

  const activityStory = useMemo(
    () =>
      (story || []).map((item, index) => (
        <BaseCol key={index} span={24}>
          <ActivityStoryItem {...item} />
        </BaseCol>
      )),
    [story],
  );

  return (
    <S.Wrapper>
      <S.Title level={2}>{t('nft.statistics')}</S.Title>
      <S.ActivityRow gutter={[26, 26]}>{activityStory}</S.ActivityRow>
    </S.Wrapper>
  );
};
