import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  // login,
  LoginRequest,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
} from '@app/api/auth.api';
import { changePasswordViaOtp, login, sendPasswordOTP } from '../../apis/user-api';
import { setUser } from '@app/store/slices/userSlice';
import { deleteToken, deleteUser, persistToken, persistUser, readToken } from '@app/services/localStorage.service';
import { getRoles } from '@app/apis/role-api';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then(async (res: any) => {

    console.log({ res })
    res.user.token = res.token;
    dispatch(setUser(res.user));


    persistUser(res.user);
    persistToken(res.token);

    return res;
  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const sendPasswordOTPAction = createAsyncThunk('auth/sendPasswordOTPAction', async (payload: any, { dispatch }) =>
  sendPasswordOTP(payload).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const changePasswordViaOtpAction = createAsyncThunk('auth/changePasswordViaOtpAction', async (payload: any, { dispatch }) =>
  changePasswordViaOtp(payload).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const getRolesAction = createAsyncThunk('roles/getALL', async (type: number, { dispatch }) =>
  getRoles(type).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      console.log('lalalalalalalalallala');
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
