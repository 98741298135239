import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import MyEmployeesList from '@app/components/manage-my-employees/list';

const ManageEmployees: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>{t('employee_external.sidebar')}</PageTitle>
      <MyEmployeesList />
    </>
  );
};

export default ManageEmployees;
