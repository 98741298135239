export interface UserType {
    url: string;
}

export interface Role {
    name: string;
    userType: keyof UserTypes;
    hide?: boolean;
}

export interface UserTypes {
    1: UserType;
    2: UserType;
}

interface AppConfig {
    API1Url: string;
    frontendURL:string,
    userTypes: UserTypes;
    roles: Role[];
    defaultPass: String,
    bucketName: String,
    roleIds: any,
    protocol:string
}

const config: AppConfig = {
    // API1Url: "http://localhost:3002",
        API1Url: "https://api.staging.clinicsnepal.com",
    // frontendURL:"http://localhost:3000",
        frontendURL:"https://panel.staging.clinicsnepal.com",
    userTypes: {
        1: { url: '/admin' },
        2: { url: '/company' }
    },
    roles: [
        { name: 'Administrator', userType: 1 },
        { name: 'Doctor', userType: 2, hide: true },
        { name: 'Client', userType: 2, hide: true },
        { name: 'Admin', userType: 2 },

        { name: 'Clinic', userType: 2, hide: true },
    ],
    defaultPass: 'Password@123',
    bucketName: 'uploads',
    roleIds: {
        Clinic: 5,
        Doctor:2,
        Client:3
    },
    protocol:'http://'
    //    protocol:'https://'
};

export default config;