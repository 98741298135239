import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ValidationFormDoctorAdd } from '@app/components/manage-my-doctors/ValidationFormAdd/ValidationForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';

const AddMyDoctor: React.FC = () => {
  const { t } = useTranslation();
  const navigate=useNavigate();
  return (
    <>
      <PageTitle>Add New Doctor</PageTitle>
      <div style={{display:'flex',justifyContent:'space-between'}}>
      <h4 style={{fontWeight:'bold'}}>Add New Doctor</h4>
      <BaseButton type="primary" onClick={() => navigate(-1)}>Back</BaseButton>

      </div>
      <ValidationFormDoctorAdd />
    </>
  );
};

export default AddMyDoctor;
