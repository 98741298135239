import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { ValidationFormMedicineAdd } from '@app/components/manage-medicines/ValidationFormAdd/ValidationForm'; 

const AddMedicine: React.FC = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  return (
    <>
      <PageTitle>Add New Medicine</PageTitle>
      {/* <BaseRow gutter={[30, 30]}>
                <BaseCol xs={24} sm={24} xl={24} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> */}
                    {/* <BaseCard id="validation form" title={ */}

                        <div style={{
                            width: '100%', display: 'flex',
                            justifyContent: ' space-between'
                        }}>

                            <span>Add New Medicine</span>

                            <BaseButton type="primary" onClick={() => navigate(-1)}>Back</BaseButton>
                        </div>

                    {/* } padding="1.25rem"> */}
                        <ValidationFormMedicineAdd />
                    {/* </BaseCard> */}
                {/* </BaseCol>
            </BaseRow > */}
     
    </>
  );
};

export default AddMedicine;
