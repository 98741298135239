import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { getAllDoctorsAction, getAvailabilitySlotsOfDoInfoAction, getInternalUserByIdAction, updateInternalUserAction } from '@app/store/slices/userSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import moment from 'moment';
import config from '@app/config';
import { readUser } from '@app/services/localStorage.service';
import { getAppointmentByIdAction, updateAppointmentAction } from '@app/store/slices/appointmentSlice';
import dayjs from 'dayjs';
import { Form, TimePicker } from 'antd';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const timeFormat = "h:mm:ss a";
export const ValidationFormMyAppointmentEdit: React.FC = () => {
  const formRef = useRef<any>();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [formData, setFormData] = useState<any>({});
  const [doctors, setDoctors] = useState([]);
  const [availabilityDetails, setAvailabilityDetails] = useState(null);
  const [doctorAppointments, setDoctorAppointments] = useState<any[]>([]);
  const [date, setDate] = useState<string>("");


  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState('Male')
  const clinicDetails = readUser();
  if (clinicDetails!.role_id == config.roleIds.Doctor) clinicDetails!.id = clinicDetails!.clinic_id;

  const { id } = useParams();


  useEffect(() => {

    dispatch(getAppointmentByIdAction(Number(id)))
      .unwrap()
      .then(async (resp) => {

        console.log({ resp })

        if (!resp.error && !resp.Error) {

          console.log(dayjs(resp.time))
          // resp.date = dayjs(resp.date);
          resp.dob = dayjs(resp.dob);

          // resp.time = moment(resp.time);
          // resp.time = moment(resp.time, timeFormat);
          resp.date = moment(resp.date, 'MM-DD-YYYY').format('ddd, MMM DD');

          getAvailabilityOfDoc(resp.doctor_id, true);
          setDate(resp.date);
          console.log('rarara', { resp })
          setFormData(resp);
          setValue(resp.gender);

        }


        setLoading(false);


      }).catch((err) => {
        notificationController.error({ message: err.message });
      })

    dispatch(getAllDoctorsAction(clinicDetails!.id))
      .unwrap()
      .then((resp) => {

        setDoctors(resp);

      }).catch((err) => {
        notificationController.error({ message: err.message });
      })


  }, [])

  const onFinish = async (values: any) => {
    setLoading(true);
    setFieldsChanged(false);
    values.clinic_id = clinicDetails?.id;
    values.dob = values.dob.toISOString();
    values.date = moment(values.date,'ddd, MMM DD').format('MM-DD-YYYY');
    // values.time = moment(new Date(values.time)).format('LT');
    values.gender = value;
    values.id = Number(id);
    dispatch(updateAppointmentAction({ id: Number(id), payload: values }))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp && !resp.error) {
          notificationController.success({ message: t('common.success') });
          navigate(-1);
        }
        else {
          notificationController.error({ message: 'Failed to save info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })

  };
  const checkIfAppointmentAvailable = (date: string, time: string) => {
    let count = 0;
    for (let i = 0; i < doctorAppointments.length; i++) {
      const appointment = doctorAppointments[i];
      const formattedDate = moment(appointment.date, 'MM-DD-YYYY').format('ddd, MMM DD');
      if (formattedDate === date && appointment.time === time) {
        count++;
      }
    }

    return count >= 2 ? false : true
  }


  const getAvailabilityOfDoc = (id: number, initial: boolean) => {

    console.log({ initial })

    dispatch(getAvailabilitySlotsOfDoInfoAction(id))
      .unwrap()
      .then((resp) => {
        console.log('resprespresp', { resp })
        if (resp && !resp.error && !resp.Error) {
          setAvailabilityDetails(resp.availableSlots);
          setDoctorAppointments(resp.doctorAppointments);
          if (initial == false) {
            console.log('lelele')
            formRef?.current?.setFieldsValue({ date: undefined, time: undefined })
          }

        }



      }).catch((err) => {
        setLoading(false);

        setAvailabilityDetails(null);
        setDoctorAppointments([]);
        formRef?.current?.setFieldsValue({date:undefined,time:undefined});
        notificationController.error({ message: err.message });
      })



  }


  return (
    <div>
      {!isLoading &&
        <Form
          // style={{pointerEvents:'none',opacity:'0.7'}}
          {...formItemLayout}
          ref={formRef}
          onFieldsChange={() => setFieldsChanged(true)}
          name="validateForm"
          initialValues={formData}

          onFinish={onFinish}
        >

          <BaseRow gutter={[10, 10]}>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="firstName"
                label={"First Name"}
                rules={[{ required: true, message: "First Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="lastName"
                label={"Last Name"}
                rules={[{ required: true, message: "Last Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="dob"
                label={"Date Of Birth"}
                rules={[{ required: true, message: "Date Of Birth is required" }]}
              >
                <DayjsDatePicker format="L" size="large" />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="phone"
                label={"Phone"}
                rules={[{ required: true, message: "Phone is required" },
                  // {
                  //   validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                  //     value.length < 3 ? cb("too short") : cb();
                  //   }, message: "Please enter a valid 10 digit phone number"
                  // }
                ]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="doctor_id"
                label={"Select a doctor"}
                hasFeedback
                rules={[{ required: true, message: "Doctor is required" }]}
              >
                <BaseSelect placeholder={"Please select a doctor"} onChange={(e: any) => getAvailabilityOfDoc(e, false)}>

                  {doctors && doctors.filter((x:any )=> x.status).map((item: any) =>

                    <Option value={item.id} disabled={!item.status}>{item.firstName} {item.lastName}</Option>

                  )}

                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="date"
                label={"Appointment Date"}
                rules={[{ required: true, message: "Appointment Date is required" }]}
              >
                {/* <DayjsDatePicker format="L" size="large" disabledDate={(current) => {
                  let customDate = dayjs().format('YYYY/MM/DD');
                  return current && current < dayjs(customDate, 'YYYY/MM/DD');
                }
                }
                /> */}




                <BaseSelect placeholder={"Please select a date"} onChange={(e: any) => setDate(e)}>

                  {availabilityDetails && Object.keys(availabilityDetails).map((key, idx, arr) => (

                    <Option value={key} >{key}</Option>

                  ))}

                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="time"
                label={"Time Of Appointment"}
                rules={[{ required: true, message: "Time Of Appointment is required" }]}
              >
                {/* <TimePicker format={timeFormat} /> */}


                <BaseSelect placeholder={"Please select a date"} 
                  defaultValue={formData.time}
                >
                  {date && availabilityDetails && availabilityDetails[date] && (availabilityDetails[date] as string[]).map((t: string) =>
                    <Option value={t} disabled={
                      !checkIfAppointmentAvailable(date, t)

                    }>{t}</Option>

                  )}
                </BaseSelect>



              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item name="gender" label="Gender" valuePropName="checked">
                <BaseRadio.Group onChange={(event) => setValue(event.target.value)} value={value}>
                  <BaseRadio value={'Male'}>Male</BaseRadio>
                  <BaseRadio value={'Female'}>Female</BaseRadio>

                </BaseRadio.Group>
              </BaseButtonsForm.Item>


            </BaseCol>



          </BaseRow>

          <BaseRow gutter={[10, 10]}>
            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item>
                <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                  {t('common.submit')}
                </BaseButton>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>

        </Form>
      }
    </div>
  );
};
