import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { UploadFile } from 'antd';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const ValidationFormFilesEdit: React.FC<any> = (props) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState('Male')
  const onFinish = async (values = {}) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setFieldsChanged(false);
      notificationController.success({ message: t('common.success') });
      console.log(values);
    }, 1000);
  };
  const defaultFileList: UploadFile<any>[] | undefined = [
    {
        "uid": "rc-upload-1698072100502-27",
        "lastModified": 1696925554142,

        "name": "CBC Count test.pdf",
        "size": 3028,
        "type": "application/pdf",
        "percent": 100,
        "status": "done",
        "response": "{\ninfo:{\nfile:{\n\"name\":\"sample.pdf\",\n\"status\":\"done\"\n}}\n}",
        "xhr": {}
    },
    {
        "uid": "rc-upload-1698072100502-28",
        "lastModified": 1696925554142,
        
        "name": "LFT test.pdf",
        "size": 3028,
        "type": "application/pdf",
        "percent": 100,

        "status": "done",
        "response": "{\ninfo:{\nfile:{\n\"name\":\"sample.pdf\",\n\"status\":\"done\"\n}}\n}",
        "xhr": {}
    },
   
];
  console.log('props.formRef', props.formRef)




  const uploadProps = {
    name: 'file',
    multiple: true,
    action: 'https://run.mocky.io/v3/a2ca41a3-fb8d-4fa4-8262-9613f424ae8a',
    onChange: (info: any) => {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
        console.log(JSON.stringify(info.fileList));
      }
      if (status === 'done') {
        notificationController.success({ message: t('uploads.successUpload', { name: info.file.name }) });
      } else if (status === 'error') {
        notificationController.error({ message: t('uploads.failedUpload', { name: info.file.name }) });
      }
    },
  };


  return (

    <BaseButtonsForm
      ref={props.formRef}

      {...formItemLayout}
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      initialValues={{
        'input-number': 3,
        'checkbox-group': ['A', 'B'],
        rate: 3.5,
      }}
      footer={
        <></>
      }
      onFinish={onFinish}
    >

      <BaseRow gutter={[10, 10]}>


        <BaseUpload {...uploadProps} defaultFileList={defaultFileList}>
          <BaseButton icon={<UploadOutlined />}>{t('uploads.clickToUpload')}</BaseButton>
        </BaseUpload>



      </BaseRow>
      {/* <BaseRow gutter={[10, 10]}>
        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item>
            <BaseButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.submit')}
            </BaseButton>
          </BaseButtonsForm.Item>
        </BaseCol>
      </BaseRow> */}

    </BaseButtonsForm>

  );
};
