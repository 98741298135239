import React, { useEffect, useState } from 'react';
import { ConfigProvider, Spin } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import { getStatesTreeAction } from './store/slices/userSlice';
import { notificationController } from './controllers/notificationController';
import { readUser } from './services/localStorage.service';

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);
  const statesTree = useAppSelector((state) => state.user.statesTree);
  const [mainLoading, setMainLoading] = useState(true);
  const dispatch = useAppDispatch();
  console.log({ theme })
  const user = readUser();
  usePWA();

  useAutoNightMode();

  useThemeWatcher();
  useEffect(() => {

    console.log({ user })
    if (user) {
      if (statesTree && statesTree.length == 0) {
        dispatch(getStatesTreeAction())
          .unwrap()
          .then((resp: any) => {

            // notificationController.success({ message: 'States loaded' });
            setMainLoading(false);

          }).catch((err: any) => {

            notificationController.error({ message: err.message });
          })
      }
    }
    else{
      setMainLoading(false);
    }
  }, [])


  return (

    mainLoading ?
      <Spin spinning={true} style={{display:'flex',justifyContent:'center',alignItems:'center',height:'1080px'}}></Spin>


      :
      <>
        <meta name="theme-color" content={themeObject[theme].primary} />
        <GlobalStyle />
        <HelmetProvider>
          <ConfigProvider locale={language === 'en' ? enUS : deDe}>
            <AppRouter />
          </ConfigProvider>
        </HelmetProvider>
      </>



  );
};

export default App;
