// axiosInstance.ts

import axios, { AxiosInstance } from 'axios';
import config from '@app/config';
const instance: AxiosInstance = axios.create({
    baseURL: config.API1Url, // Replace with your API base URL
    timeout: 35000, // Set the timeout for requests (optional)
    headers: {
        'Content-Type': 'application/json',
        // Add any other default headers you need
    },
});

export default instance;