import * as React from 'react';
import { Component } from 'react';
import { PageTitle } from '../common/PageTitle/PageTitle';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseCard } from '../common/BaseCard/BaseCard';
import { ValidationFormUserAdd } from './ValidationFormAdd/ValidationForm';
import { useTranslation } from 'react-i18next';
import { ValidationFormUserEdit } from './ValidationFormEdit/ValidationForm';
import { BaseButton } from '../common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';


const EditUser: React.FC = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();


    return (
        <>
            <PageTitle>Edit User</PageTitle>

            <BaseRow gutter={[30, 30]}>
                <BaseCol xs={24} sm={24} xl={24}>
                    <BaseCard id="validation form" title={

                        <div style={{
                            width: '100%', display: 'flex',
                            justifyContent: ' space-between'
                        }}>

                            <span>Edit User</span>

                            <BaseButton type="primary" onClick={() => navigate(-1)}>Back</BaseButton>
                        </div>

                    } padding="1.25rem">
                        <ValidationFormUserEdit />
                    </BaseCard>
                </BaseCol>
            </BaseRow>
        </>
    );

}

export default EditUser;