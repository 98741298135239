import React, { useState } from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { InvitationEmailsModal } from './InvitationEmaillsModal';
import { readUser } from '@app/services/localStorage.service';
import config from '@app/config';
import { notificationController } from '@app/controllers/notificationController';
import { BaseDropdown } from '@app/components/common/BaseDropdown/Dropdown';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { DownOutlined } from '@ant-design/icons';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = (props) => {
  const toggleSider = props.toggleSider;
  const isSiderOpened = props.isSiderOpened;
  const user = readUser();
  const [open, setInvitationModal] = useState(false);
  const [key, setKey] = useState(0);
  const menuItems = [
    {
      key: 1,
      label: 'Copy Onboard Link'
    },
    {
      key: 2,
      label: 'Send bulk invite'
    }
  ]

  const onClick = (e: any) => {
    console.log({ e });
    setKey(e.key);
    if (e.key == 1) {
      if (user!.user_type == 1) {
        navigator.clipboard.writeText(config.frontendURL + '/clinic-onboard');
        notificationController.success({ message: 'Clinic Onboard Link Copied' });
      }
      else {
        navigator.clipboard.writeText(config.frontendURL + '/doctor-onboard?clinicId=' + user!.id);
        notificationController.success({ message: 'Doctor Onboard Link Copied' });
      }
    }
    else {

      setInvitationModal(true);
    }
  }
  let userRole = user!.role_id;
  return (
    <BaseRow justify="space-between" align="middle">
      <InvitationEmailsModal
        key={key}
        open={open}
        setInvitationModal={setInvitationModal}
        user={user}
      />
      <BaseCol>
        <ProfileDropdown />
      </BaseCol>


      {userRole !== config.roleIds['Doctor'] &&

        <BaseDropdown menu={{ items: menuItems, onClick }} trigger={['click']}>
          <BaseButton onClick={(e) => e.preventDefault()}>
            Onboard <DownOutlined />
          </BaseButton>
        </BaseDropdown>

      }


      <BaseCol>
        <BaseRow align="middle">
          {/* <BaseCol>
            <NotificationsDropdown />
          </BaseCol> */}

          {/* <BaseCol>
            <HeaderSearch />
          </BaseCol> */}

          <BaseCol>
            <SettingsDropdown />
          </BaseCol>
        </BaseRow>
      </BaseCol>

      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
      </S.BurgerCol>
    </BaseRow>
  );
};
