import { Priority } from '../constants/enums/priorities';

export interface Tag {
  value: string;
}

export interface BasicTableRow {
  key: number;
  name: string;
  age: number;
  address: string;
  tags?: Tag[];
  status: boolean,
  email?: string,
  phone?: string,
  city: string,
  time?: string
  doctor?:string
}

export interface BasicPatientRow{

  key:number,
  firstName:string,
  lastName:string,
  mobile:string,
  dob:Date,
  gender:string,
  address:string,
  city:string,
  state:string,
  notes?:string

}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface BasicTableData {
  data: BasicTableRow[];
  pagination: Pagination;
}

export interface BasicPatientsData {
  data: BasicPatientRow[];
  pagination: Pagination;
}

export const getBasicTableData = (pagination: Pagination): Promise<BasicTableData> => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: [
          {
            key: 1,
            name: 'Atulya Clinic',
            age: 32,
            address: 'Mohali. 1 Lake Park',
            tags: [
              { value: 'General practitioner' },
              { value: 'Neurologist' },
              { value: 'Surgeon' },
            ],
            email: 'info@atulya.com',
            phone: '3344445566',
            city: 'Mohali',
            status: true
          },
          {
            key: 2,
            name: 'Max Speciality Clinic',
            age: 42,
            address: 'London No. 1 Lake Park',
            tags: [{ value: 'General practitioner' },
            { value: 'Neurologist' },
            { value: 'Surgeon' },],
            email: 'info@max.com',
            phone: '4455445566',
            city: 'Chandigarh',
            status: false
          },
          {
            key: 3,
            name: 'Fortis Special Clinic',
            age: 32,
            address: 'Delhi, No. 1 Lake Park',
            tags: [
              { value: 'General practitioner' },
              { value: 'Neurologist' },
              { value: 'Surgeon' },
            ],
            email: 'info@fortis.com',
            phone: '3344443344',
            city: 'Delhi',
            status: true
          },
          {
            key: 4,
            name: 'Civil Clinic',
            age: 30,
            address: 'Kolkata, No. 1 Lake Park',
            tags: [
              { value: 'Engineer' },
              { value: 'Architect' },
            ],
            email: 'info@civil.com',
            phone: '3344444433',
            city: 'Kolkata',
            status: true
          },
          {
            key: 5,
            name: 'Alex Brown',
            age: 26,
            address: 'Minsk',
            tags: [{ value: 'Engineer' }],
            email: '',
            phone: '',
            city: '',
            status: false
          },
          {
            key: 6,
            name: 'Josh Black',
            age: 21,
            address: 'New York No. 1 Lake Park',
            tags: [
              { value: 'Teacher' },
              { value: 'Architect' },
            ],
            email: '',
            phone: '',
            city: '',
            status: true
          },
          {
            key: 7,
            name: 'Cris Green',
            age: 22,
            address: 'Sidney No. 1 Lake Park',
            tags: [{ value: 'Architect' }],
            email: '',
            phone: '',
            city: '',
            status: true
          },
          {
            key: 8,
            name: 'Jaime Black',
            age: 23,
            address: 'New York No. 1 Lake Park',
            tags: [{ value: 'Engineer' }],
            email: '',
            phone: '',
            city: '',
            status: false
          },
          {
            key: 9,
            name: 'Alina Brown',
            age: 19,
            address: 'Minsk',
            tags: [
              { value: 'Professor' },
              { value: 'Teacher' },
            ],
            email: '',
            phone: '',
            city: '',
            status: true
          },
          {
            key: 10,
            name: 'Cris Brown',
            age: 25,
            address: 'London',
            tags: [
              { value: 'Engineer' },
              { value: 'Teacher' },
            ],
            email: '',
            phone: '',
            city: '',
            status: false
          },
          {
            key: 11,
            name: 'Alina Fens',
            age: 19,
            address: 'Minsk',
            tags: [
              { value: 'Professor' },
              { value: 'Teacher' },
            ],
            email: '',
            phone: '',
            city: '',
            status: true
          },
          {
            key: 12,
            name: 'Alex Snak',
            age: 28,
            address: 'Brest',
            tags: [
              { value: 'Professor' },
              { value: 'Doctor' },
            ],
            email: '',
            phone: '',
            city: '',
            status: true
          },
          {
            key: 13,
            name: 'Pavel Dubrouski',
            age: 26,
            address: 'Minsk',
            tags: [
              { value: 'Professor' },
              { value: 'Doctor' },
              { value: 'Teacher' },
              { value: 'Engineer' },
            ],
            email: '',
            phone: '',
            city: '',
            status: true
          },

        ],
        pagination: { ...pagination, total: 10 },
      });
    }, 1000);
  });
};


export const getBasicTableDataUsers = (pagination: Pagination): Promise<BasicTableData> => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: [
          {
            key: 5,
            name: 'Alex Brown',
            age: 26,
            address: 'Minsk',
            tags: [{ value: 'Engineer' }],
            email: '',
            phone: '',
            city: 'NY',
            status: false
          },
          {
            key: 6,
            name: 'Josh Black',
            age: 21,
            address: 'New York No. 1 Lake Park',
            tags: [
              { value: 'Teacher' },
              { value: 'Architect' },
            ],
            email: '',
            phone: '',
            city: 'NY',
            status: true
          },
          {
            key: 7,
            name: 'Cris Green',
            age: 22,
            address: 'Sidney No. 1 Lake Park',
            tags: [{ value: 'Architect' }],
            email: '',
            phone: '',
            city: 'NY',
            status: true
          },
          {
            key: 8,
            name: 'Jaime Black',
            age: 23,
            address: 'New York No. 1 Lake Park',
            tags: [{ value: 'Engineer' }],
            email: '',
            phone: '',
            city: 'NY',
            status: false
          },
          {
            key: 9,
            name: 'Alina Brown',
            age: 19,
            address: 'Minsk',
            tags: [
              { value: 'Professor' },
              { value: 'Teacher' },
            ],
            email: '',
            phone: '',
            city: 'NY',
            status: true
          },
          {
            key: 10,
            name: 'Cris Brown',
            age: 25,
            address: 'London',
            tags: [
              { value: 'Engineer' },
              { value: 'Teacher' },
            ],
            email: '',
            phone: '',
            city: 'NY',
            status: false
          },
          {
            key: 11,
            name: 'Alina Fens',
            age: 19,
            address: 'Minsk',
            tags: [
              { value: 'Professor' },
              { value: 'Teacher' },
            ],
            email: '',
            phone: '',
            city: '',
            status: true
          },
          {
            key: 12,
            name: 'Alex Snak',
            age: 28,
            address: 'Brest',
            tags: [
              { value: 'Professor' },
              { value: 'Doctor' },
            ],
            email: '',
            phone: '',
            city: '',
            status: true
          },
          {
            key: 13,
            name: 'Pavel Dubrouski',
            age: 26,
            address: 'Minsk',
            tags: [
              { value: 'Professor' },
              { value: 'Doctor' },
              { value: 'Teacher' },
              { value: 'Engineer' },
            ],
            email: '',
            phone: '',
            city: '',
            status: true
          },

        ],
        pagination: { ...pagination, total: 10 },
      });
    }, 1000);
  });
};

export const getPatientsData = (pagination: Pagination): Promise<BasicPatientsData> => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: [
          {
            key: 1,
            firstName:'Vijay',
            lastName:'Kumar',
            mobile:'9988776655',
            dob:new Date(),
            gender:'Male',
            address:'ejbfjsbjgbrdjkgdg',
            city:'Mohali',
            state:'Punjab',
          },
          {
            key: 2,
            firstName:'Vijay',
            lastName:'Kumar',
            mobile:'9988776655',
            dob:new Date(),
            gender:'Male',
            address:'ejbfjsbjgbrdjkgdg',
            city:'Mohali',
            state:'Punjab',
          },
          {
            key: 3,
            firstName:'Vijay',
            lastName:'Kumar',
            mobile:'9988776655',
            dob:new Date(),
            gender:'Male',
            address:'ejbfjsbjgbrdjkgdg',
            city:'Mohali',
            state:'Punjab',
          },          {
            key: 4,
            firstName:'Vijay',
            lastName:'Kumar',
            mobile:'9988776655',
            dob:new Date(),
            gender:'Male',
            address:'ejbfjsbjgbrdjkgdg',
            city:'Mohali',
            state:'Punjab',
          },          {
            key: 5,
            firstName:'Vijay',
            lastName:'Kumar',
            mobile:'9988776655',
            dob:new Date(),
            gender:'Male',
            address:'ejbfjsbjgbrdjkgdg',
            city:'Mohali',
            state:'Punjab',
          },          {
            key: 6,
            firstName:'Vijay',
            lastName:'Kumar',
            mobile:'9988776655',
            dob:new Date(),
            gender:'Male',
            address:'ejbfjsbjgbrdjkgdg',
            city:'Mohali',
            state:'Punjab',
          },          {
            key: 7,
            firstName:'Vijay',
            lastName:'Kumar',
            mobile:'9988776655',
            dob:new Date(),
            gender:'Male',
            address:'ejbfjsbjgbrdjkgdg',
            city:'Mohali',
            state:'Punjab',
          },          {
            key: 8,
            firstName:'Vijay',
            lastName:'Kumar',
            mobile:'9988776655',
            dob:new Date(),
            gender:'Male',
            address:'ejbfjsbjgbrdjkgdg',
            city:'Mohali',
            state:'Punjab',
          },
        ],
        pagination: { ...pagination, total: 8 },
      });
    }, 1000);
  });
};


export const getBasicTableDataUAppointments = (pagination: Pagination): Promise<BasicTableData> => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: [
          {
            key: 5,
            name: 'Alex Brown',
            age: 26,
            address: 'Minsk',
            tags: [{ value: 'Engineer' }],
            email: '',
            phone: '',
            city: 'NY',
            status: false,
            time: "2/4/2010 2:39:28 PM",
            doctor:"James Smith"
          },
          {
            key: 6,
            name: 'Josh Black',
            age: 21,
            address: 'New York No. 1 Lake Park',
            tags: [
              { value: 'Teacher' },
              { value: 'Architect' },
            ],
            email: '',
            phone: '',
            city: 'NY',
            status: true,
            time: "2/4/2010 2:39:28 PM",
            doctor:"James Smith"
          },
          {
            key: 7,
            name: 'Cris Green',
            age: 22,
            address: 'Sidney No. 1 Lake Park',
            tags: [{ value: 'Architect' }],
            email: '',
            phone: '',
            city: 'NY',
            status: true,
            time: "2/4/2010 2:39:28 PM",
            doctor:"James Smith"
          },
          {
            key: 8,
            name: 'Jaime Black',
            age: 23,
            address: 'New York No. 1 Lake Park',
            tags: [{ value: 'Engineer' }],
            email: '',
            phone: '',
            city: 'NY',
            status: false,
            time: "2/4/2010 2:39:28 PM",
            doctor:"James Smith"
          },
          {
            key: 9,
            name: 'Alina Brown',
            age: 19,
            address: 'Minsk',
            tags: [
              { value: 'Professor' },
              { value: 'Teacher' },
            ],
            email: '',
            phone: '',
            city: 'NY',
            status: true,
            time: "2/4/2010 2:39:28 PM",
            doctor:"James Smith"
          },
          {
            key: 10,
            name: 'Cris Brown',
            age: 25,
            address: 'London',
            tags: [
              { value: 'Engineer' },
              { value: 'Teacher' },
            ],
            email: '',
            phone: '',
            city: 'NY',
            status: false,
            time: "2/4/2010 2:39:28 PM",
            doctor:"James Smith"
          },
          {
            key: 11,
            name: 'Alina Fens',
            age: 19,
            address: 'Minsk',
            tags: [
              { value: 'Professor' },
              { value: 'Teacher' },
            ],
            email: '',
            phone: '',
            city: '',
            status: true,
            time: "2/4/2010 2:39:28 PM",
            doctor:"James Smith"
          },
          {
            key: 12,
            name: 'Alex Snak',
            age: 28,
            address: 'Brest',
            tags: [
              { value: 'Professor' },
              { value: 'Doctor' },
            ],
            email: '',
            phone: '',
            city: '',
            status: true,
            doctor:"James Smith"
          },
          {
            key: 13,
            name: 'Pavel Dubrouski',
            age: 26,
            address: 'Minsk',
            tags: [
              { value: 'Professor' },
              { value: 'Doctor' },
              { value: 'Teacher' },
              { value: 'Engineer' },
            ],
            email: '',
            phone: '',
            city: '',
            status: true,
            time: "2/4/2010 2:39:28 PM",
            doctor:"James Smith"
          },

        ],
        pagination: { ...pagination, total: 10 },
      });
    }, 1000);
  });
};