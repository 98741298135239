import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { OTPCodeForm } from '@app/components/auth/OTPCodeForm/OTPCodeForm';
import {useLocation} from 'react-router-dom';
const OTPCodePage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
console.log({location})
  return (
    <>
      <PageTitle>{t('common.securityCode')}</PageTitle>
      <OTPCodeForm values={location.state}/>
    </>
  );
};

export default OTPCodePage;
