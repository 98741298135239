import axios from "@app/services/axios";




export const createPatientData = (payload: any): Promise<any> =>
    axios.post<any>('/patientData', payload).then((res: any) => {

        console.log('inside api',res)
        return res.data;

  
    });

export const getPatientData = (patiend_id: number, clinic_id: number): Promise<any> =>
    axios.get<any>('/patientData/' + patiend_id + '/' + clinic_id).then((res: any) => {


        return res.data;

    }).catch((err)=>{

        throw(err);

    });


    export const createVitalsData = (payload: any): Promise<any> =>
    axios.post<any>('/patientData/vitals', payload).then((res: any) => {

        console.log('inside api',res)
        return res.data;

  
    });


    export const updateVitalsData = (id:number,payload: any): Promise<any> =>
    axios.put<any>('/patientData/vitals/'+id, payload).then((res: any) => {

        console.log('inside api',res)
        return res.data;

  
    });


    export const createNotesData = (payload: any): Promise<any> =>
    axios.post<any>('/patientData/clinicalnotes', payload).then((res: any) => {

        console.log('inside api',res)
        return res.data;

  
    });
    export const updateNotesData = (id:number,payload: any): Promise<any> =>
    axios.put<any>('/patientData/clinicalnotes/'+id, payload).then((res: any) => {

        console.log('inside api',res)
        return res.data;

  
    });


    export const getMedicineByName = (name:string): Promise<any> =>
    axios.get<any>('/miscellaneous/medicines/'+name).then((res: any) => {

        console.log('inside api',res)
        return res.data;

  
    });


    export const getLabTestByName = (name:string): Promise<any> =>
    axios.get<any>('/miscellaneous/labtests/'+name).then((res: any) => {

        console.log('inside api',res)
        return res.data;

  
    });

    export const createPrescriptionsData = (id:number,payload: any): Promise<any> =>
    axios.post<any>('/patientData/prescription/'+id, payload).then((res: any) => {

        console.log('inside api',res)
        return res.data;

  
    });
    export const updatePrescriptionsData = (id:number,payload: any): Promise<any> =>
    axios.put<any>('/patientData/prescription/'+id, payload).then((res: any) => {

        console.log('inside api',res)
        return res.data;

  
    });

    export const createLabTestData = (id:number,payload: any): Promise<any> =>
    axios.post<any>('/patientData/labtests/'+id, payload).then((res: any) => {

        console.log('inside api',res)
        return res.data;

  
    });
    export const updateLabTestData = (id:number,payload: any): Promise<any> =>
    axios.put<any>('/patientData/labtests/'+id, payload).then((res: any) => {

        console.log('inside api',res)
        return res.data;

  
    });
