import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { createInternalUserAction } from '@app/store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { createVitalsAction } from '@app/store/slices/patientDataSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const ValidationFormVitalsAdd: React.FC<any> = (props) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState('Male')
  const dispatch = useAppDispatch();
  const navigate = useNavigate();




  const onFinish = async (values: any) => {
    setLoading(true);
    setFieldsChanged(false);
    values.patient_data_id = props.patientDataId;
    console.log(values);

    // return;
    dispatch(createVitalsAction(values))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp && !resp.error && !resp.Error) {
          notificationController.success({ message: t('common.success') });
          props.setAddType('');
          props.getPatientDataAll();
        }
        else {
          notificationController.error({ message: 'Failed to save patient info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })

  };

  return (

    <BaseButtonsForm

      // style={{ pointerEvents: 'none', opacity: '0.7' }}
      {...formItemLayout}
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      // initialValues={}
      footer={<></>}
      onFinish={onFinish}
    >

      <BaseRow gutter={[10, 10]}>


        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item
            name="weight"
            label={"Weight"}
            // rules={[{ required: true, message: "Weight is required" }]}
          >
             <InputNumber />
          </BaseButtonsForm.Item>
        </BaseCol>

        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item

            name="bp"
            label={"Blood Pressure"}
            // rules={[{ required: true, message: "Blood Pressure is required" }]}
          >
            <BaseInput placeholder='80 Lower, 120 High' />
          </BaseButtonsForm.Item>
        </BaseCol>

        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item

            name="pulse"
            label={"Pulse"}
            // rules={[{ required: true, message: "Pulse is required" }]}
          >
              <InputNumber />
          </BaseButtonsForm.Item>
        </BaseCol>
        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item

            name="temperature"
            label={"Temperature (C)"}
            // rules={[{ required: true, message: "Temperature is required" }]}
          >
              <InputNumber />
          </BaseButtonsForm.Item>
        </BaseCol>

        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item

            name="respRate"
            label={"Resp. Rate(Breaths/min)"}
            // rules={[{ required: true, message: "Resp. Rate is required" }]}
          >
              <InputNumber />
          </BaseButtonsForm.Item>
        </BaseCol>

      </BaseRow>
      <BaseRow gutter={[10, 10]}>
        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item>
            <BaseButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.submit')}
            </BaseButton>
          </BaseButtonsForm.Item>
        </BaseCol>
      </BaseRow>

    </BaseButtonsForm>

  );
};
