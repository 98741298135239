import React from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import { NftItem } from '@app/api/nftDashboard.api';
import { formatNumberWithCommas, getCurrencyPrice } from '@app/utils/utils';
import { CurrencyTypeEnum } from '@app/interfaces/interfaces';
import * as S from './NftCard.styles';
import config from '@app/config';

interface NftCardProps {
  nftItem: NftItem;
}

export const NftCard: React.FC<any> = ({ nftItem }) => {
  const { isTablet } = useResponsive();

  const tabletLayout = (
    <>
      <S.InfoHeader>
        <S.InfoText>{nftItem.email} </S.InfoText>
      </S.InfoHeader>

      {/* <S.InfoFooter>
        <S.CurrentBidWrapper>
          <S.CurrentBid>Some Info</S.CurrentBid>
          <S.BidCrypto>
        
            Some description here
          </S.BidCrypto>
        </S.CurrentBidWrapper>

        <S.CurrentBidWrapper>

        </S.CurrentBidWrapper>
      </S.InfoFooter> */}
    </>
  );

  const mobileLayout = (
    <>
      <S.InfoRow>
        <S.InfoText>{nftItem.email}</S.InfoText>
        {/* <S.BidCrypto>
          {getCurrencyPrice(formatNumberWithCommas(nftItem.currentBidCrypto), CurrencyTypeEnum.ETH, false)}
        </S.BidCrypto> */}
      </S.InfoRow>

      {/* <S.InfoRow>
        <S.CurrentBid>Current Bid</S.CurrentBid>
        <S.Bid>{getCurrencyPrice(formatNumberWithCommas(nftItem.currentBid), CurrencyTypeEnum.USD)}</S.Bid>
      </S.InfoRow> */}
    </>
  );

  return (
    <S.Card padding={0} $img={nftItem?.image?.url? config.API1Url +  '/' +nftItem?.image?.url : ''}>
      <S.NftImage src={nftItem?.image?.url? config.API1Url + '/' + nftItem?.image?.url : ''} alt="nftImage" />
      <S.NftInfo>
        <S.InfoRow>
          <S.Title>{nftItem?.firstName} {nftItem?.lastName}</S.Title>
        </S.InfoRow>
        {isTablet ? tabletLayout : mobileLayout}
      </S.NftInfo>
    </S.Card>
  );
};
