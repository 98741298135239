import axios from "@app/services/axios";




export const getRoles = (type: number): Promise<any> =>
    axios.get<any>('/roles?type=' + type).then((res: any) => {


        return res.data;

    });

