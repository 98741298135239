import React, { useEffect } from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'hooks/useResponsive';
import logo from 'assets/no-image.png';
import maxx from 'assets/maxx.jpeg';
import logoDark from 'assets/logo-dark.png';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { readUser } from '@app/services/localStorage.service';
import config from '@app/config';
import { getInternalUserByIdAction, setUser } from '@app/store/slices/userSlice';
import { notificationController } from '@app/controllers/notificationController';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { tabletOnly } = useResponsive();
  let user = readUser();
  console.log('inside sider',user);
  const dispatch = useAppDispatch();
  useEffect(() => {


    dispatch(getInternalUserByIdAction(Number(user!.id)))
      .unwrap()
      .then((resp) => {

        setUser(resp.userInfo);

      }).catch((err) => {
        notificationController.error({ message: err.message });
      })

  }, [])

  const theme = useAppSelector((state) => state.theme.theme);

  let img = logo;
  // user!.role !== "Administrator" ? img = maxx : img = img;
  if (user && user.image) {
    img = user.image.url ? img = config.API1Url + '/' + user.image.url : img = img;
  }
  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to={'#'}>
        <img src={img} alt="Nepal CLinics" width={48} height={48} />
        <S.BrandSpan>{user!.firstName} {user!.lastName}</S.BrandSpan>
      </S.SiderLogoLink>
      {tabletOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
