import React, { useState } from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Button } from 'antd';
import config from '@app/config';
import { notificationController } from '@app/controllers/notificationController';
import { readUser } from '@app/services/localStorage.service';
import { BaseDropdown } from '@app/components/common/BaseDropdown/Dropdown';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { DownOutlined } from '@ant-design/icons';
import { InvitationEmailsModal } from './InvitationEmaillsModal';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const user = readUser();
  const [open, setInvitationModal] = useState(false);
  const [key, setKey] = useState(0);
  const menuItems = [
    {
      key: 1,
      label: 'Copy Onboard Link'
    },
    {
      key: 2,
      label: 'Send bulk invite'
    }
  ]

  const onClick = (e: any) => {
    console.log({ e });
    setKey(e.key);
    if (e.key == 1) {
      if (user!.user_type == 1) {
        navigator.clipboard.writeText(config.frontendURL + '/clinic-onboard');
        notificationController.success({ message: 'Clinic Onboard Link Copied' });
      }
      else {
        navigator.clipboard.writeText(config.frontendURL + '/doctor-onboard?clinicId=' + user!.id);
        notificationController.success({ message: 'Doctor Onboard Link Copied' });
      }
    }
    else {

      setInvitationModal(true);
    }
  }
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={15} xxl={12}>
          {/* <HeaderSearch /> */}
        </BaseCol>
        {/* <BaseCol>
          <S.GHButton />
        </BaseCol> */}
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol lg={10} xxl={8}>
        {/* <HeaderSearch /> */}
      </BaseCol>
      {/* <BaseCol>
        <S.GHButton />
      </BaseCol> */}
    </>
  );
  let userRole = user!.role_id;
  return (
    <BaseRow justify="space-between" align="middle">
      <InvitationEmailsModal
      key={key}
        open={open}
        setInvitationModal={setInvitationModal}
        user={user}
      />
      {leftSide}

      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow align="middle" justify="end" gutter={[5, 5]}>
          <BaseCol>
            <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}>
              <BaseCol>
                {userRole !== config.roleIds['Doctor'] &&

                  <BaseDropdown menu={{ items: menuItems, onClick }} trigger={['click']}>
                    <BaseButton onClick={(e) => e.preventDefault()}>
                      Onboard <DownOutlined />
                    </BaseButton>
                  </BaseDropdown>

                }
              </BaseCol>
              <BaseCol>
                <HeaderFullscreen />
              </BaseCol>

              {/* <BaseCol>
                <NotificationsDropdown />
              </BaseCol> */}

              <BaseCol>
                <SettingsDropdown />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol>
            <ProfileDropdown />
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  );
};
