import React from 'react';
import { useTranslation } from 'react-i18next';
import {  useNavigate } from "react-router-dom";
import { BasicTable } from './BasicTable/BasicTable';
import { BaseButton } from '../common/BaseButton/BaseButton';

const MyTestimonialsComponent: React.FC = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    return (
        <>
            <div style={{ marginBottom: '20px',flexDirection:'row',display:'flex',justifyContent: 'space-between' }}>
                <h4 style={{fontWeight:'bold'}}>Manage Testimonials</h4>
                <BaseButton type="primary" onClick={()=>navigate('/admin/testimonial/add')}>Add New</BaseButton>
            </div>

            <BasicTable />
        </>
    );
};

export default MyTestimonialsComponent;
