import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { getAllSpecialitiesAction, getInternalUserByIdAction, updateInternalUserAction } from '@app/store/slices/userSlice';
import config from '@app/config';
import moment from 'moment';
import dayjs from 'dayjs';
import { DatePicker, Form } from 'antd';
import { readUser } from '@app/services/localStorage.service';
import { returnArea } from '@app/utils/utils';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};



export const ValidationFormDoctorEdit: React.FC = () => {
  const formRef = useRef<any>();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [formData, setFormData] = useState<any>({});
  const [fileIdDocs, setFileIdDocs] = useState(null);

  const [specialities, setSpecialities] = useState([]);
  const [fileId, setFileId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();



  const statesTree = useAppSelector((state) => state.user.statesTree);
  const [districts, setDistricts] = useState([]);
  const [gaunpalikas, setGaunpalikas] = useState([]);
  const [state, setState] = useState(null);
  const [district, setDistrict] = useState(null);
  const [gaunPalika, setGaunPalika] = useState(null);
  const [formReset, setFormReset] = useState(false);
  const [value, setValue] = useState('Male')


  const clinicDetails = readUser();

  const normFile = (e: any) => {
    console.log({ e })
    if (e.file.status == 'done') {
      console.log('e.file?.response?.file?.file_id', e.file?.response?.file?.file_id);
      setFileId(e.file?.response?.file?.file_id);
    }
    if (Array.isArray(e.fileList)) {
      return e.fileList;
    }

    return e && e.fileList;
  };


  const { id } = useParams();
  useEffect(() => {



    dispatch(getAllSpecialitiesAction(1))
      .unwrap()
      .then((resp) => {

        setSpecialities(resp);

      }).catch((err) => {
        notificationController.error({ message: err.message });
      })

    dispatch(getInternalUserByIdAction(Number(id)))
      .unwrap()
      .then((resp) => {

        console.log({ resp })

        setFileId(resp.userInfo.avatar);
        let arr = resp && resp.avatar && Array.isArray(resp.avatar) && resp.avatar[0] !== null && resp.avatar.flatMap((x: any) => [{ name: x.name, status: 'done', url: config.API1Url + '/' + x.url, uid: x.file_id }]) || []
        resp.userInfo.avatar = arr;
        resp.userInfo.specialities = resp.specialities;
        resp.userInfo.dob = moment(resp.userInfo.dob);
        setValue(resp?.userInfo?.gender);
        let docsArr = resp.userInfo.docs && resp.userInfo.docs[0] !== null && resp.userInfo.docs.flatMap((x: any) => [{ name: x.name, status: 'done', url: config.API1Url + '/' + x.url, uid: x.file_id }]) || []
        resp.userInfo.docs = docsArr;
        // delete resp.dob;
        console.log({ resp })
        setFormData(resp.userInfo);
        areasMapper(resp.userInfo);
        setLoading(false);


      }).catch((err) => {
        notificationController.error({ message: err.message });
      })


  }, [])



  useEffect(() => {
    let _formData = formData;
    _formData!.state = state;
    setFormReset(true);
    setDistrict(null);
    _formData!.district = null;
    setGaunPalika(null);
    _formData!.city = null;
    formRef?.current?.setFieldsValue({ city: undefined });
    formRef?.current?.setFieldsValue({ district: undefined });
    setDistricts([]);
    setGaunpalikas([]);
    setFormData(_formData);
    setDistricts(returnArea(_formData.state, null, statesTree));


    setTimeout(() => {


      setFormReset(false);

    }, 50);
  }, [state])


  useEffect(() => {
    let _formData = formData;

    setFormReset(!formReset);
    _formData.district = district;
    setGaunPalika(null);
    formRef?.current?.setFieldsValue({ city: undefined });

    _formData!.city = null;
    setGaunpalikas([]);

    setGaunpalikas(returnArea(_formData.state, _formData.district, statesTree));

    setTimeout(() => {

      setFormReset(false);

    }, 50);


  }, [district])






  const areasMapper = (data: any) => {

    setDistricts(returnArea(data.state, null, statesTree));
    setGaunpalikas(returnArea(data.state, data.district, statesTree));

  }



  console.log(dayjs(formData?.dob));
  const onFinish = async (values: any) => {
    console.log('before', JSON.stringify(values));
    setLoading(true);
    setFieldsChanged(false);
    console.log(values);
    values.avatar = fileId;
    // values.password = config.defaultPass;
    // values.confirmPassword = config.defaultPass;
    values.user_type = 2;
    values.role_id = config.roleIds['Doctor'];
    typeof (values.status) !== 'boolean' ? values.status = false : values.status = values.status;
    values.clinic_id = clinicDetails?.id;
    values.gender=value;
    let otherDocsArr = values.docs?.flatMap((file: any) => file?.response ? file?.response?.file?.file_id : file?.uid);

    values.docs = otherDocsArr;

    console.log(values);
    values.id = Number(id);
    dispatch(updateInternalUserAction(values))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp.success) {
          notificationController.success({ message: t('common.success') });
          navigate(-1);
        }
        else {
          notificationController.error({ message: 'Failed to save clinic' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })

  };
  const normFileDocs = (e: any) => {
    console.log({ e })
    if (e.file.status == 'done') {
      console.log('e.file?.response?.file?.file_id', e.file?.response?.file?.file_id);
      setFileIdDocs(e.file?.response?.file?.file_id);
    }
    if (Array.isArray(e.fileList)) {
      return e.fileList;
    }

    return e && e.fileList;
  };
  return (
    <div>
      {!isLoading &&
        <Form
          {...formItemLayout}
          ref={formRef}
          name="validateForm"
          initialValues={formData}

          onFinish={onFinish}
        >

          <BaseRow gutter={[10, 10]}>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="firstName"
                label={"First Name"}
                rules={[{ required: true, message: "First Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="lastName"
                label={"Last Name"}
                rules={[{ required: true, message: "Last Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>



            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="email"
                label={"Email"}
                rules={[{ required: true, type: "email", message: "Email is required and must be a valid email" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="userName"
                label={"Username"}
                rules={[{ required: true, message: "Username is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="phone"
                label={"Phone"}
                rules={[{ required: true, message: "Phone is required" },
                  // {
                  //   validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                  //     value.length < 3 ? cb("too short") : cb();
                  //   }, message: "Please enter a valid 10 digit phone number"
                  // }
                ]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="address"
                label={"Address"}
                rules={[{ required: true, message: "Address is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="government_id"
                label={"Government ID"}
                // rules={[{ required: true, message: "Government ID is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="pincode"
                label={"Pincode"}
                rules={[{ required: true, message: "Pincode is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>



            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="state"
                label={"Select State"}
                hasFeedback
                rules={[{ required: true, message: "State is required" }]}
                initialValue={state}
              >
                <BaseSelect placeholder={"Please select a state"} onChange={(e: any) => setState(e)}>
                  {statesTree && statesTree.map((item: any) =>
                    <Option value={item?.id}>{item?.name}</Option>

                  )}

                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="district"
                label={"Select District"}
                hasFeedback
                rules={[{ required: true, message: "District is required" }]}
                initialValue={district}

              >
                <BaseSelect placeholder={"Please select a district"} onChange={(e: any) => setDistrict(e)}>
                  {districts && districts.map((item: any) =>
                    <Option value={item?.id}>{item?.district_name}</Option>

                  )}


                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="city"
                label={"Select Gaunpalika"}
                hasFeedback
                rules={[{ required: true, message: "Gaunpalika is required" }]}
                initialValue={gaunPalika}
              >
                <BaseSelect placeholder={"Please select a gaunpalika"} onChange={(e: any) => setGaunPalika(e)}>
                  {gaunpalikas && gaunpalikas.map((item: any) =>
                    <Option value={item?.id}>{item?.gaunpalika_name}</Option>

                  )}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item name="gender" label="Gender" valuePropName="checked">
                <BaseRadio.Group onChange={(event) => setValue(event.target.value)} value={value}>
                  <BaseRadio value={'Male'}>Male</BaseRadio>
                  <BaseRadio value={'Female'}>Female</BaseRadio>

                </BaseRadio.Group>
              </BaseButtonsForm.Item>


            </BaseCol>
            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="dob"
                label={"Date of Birth"}
                hasFeedback
                rules={[{ required: true, message: "DOB is required" }]}
                initialValue={moment()}

              >
                <DatePicker value={moment()}
                />
              </BaseButtonsForm.Item>
            </BaseCol>




            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="specialities"
                label={"Select Specialities"}
                rules={[{ required: true, message: "Specialities are required", type: 'array' }]}
              >
                <BaseSelect mode="multiple" placeholder={"Please select a specility"}>
                  {specialities && specialities.length > 0 && specialities.map((item: any) =>
                    <Option value={item.id}>{item.name}</Option>
                  )}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>



            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="consuntantFee"
                label={"Consuntant Fee"}
                rules={[{ required: true, message: "Consuntant Fee is required" }]}
              >
                <BaseInput type='number' />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="education"
                label={"Education"}
                rules={[{ required: true, message: "Education required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="experience"
                label={"Experience (in years)"}
                rules={[{ required: true, message: "Experience is required" }]}
              >
                <BaseInput type='number' />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="description"
                label={"Description"}
                rules={[{ required: true, message: "Description is required" }]}
              >
                <BaseInput.TextArea rows={4} />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="avatar"
                label={"Upload your Avatar"}
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: "Please select an avatar"
                  }
                ]}
                getValueFromEvent={normFile}
              >
                <BaseUpload name="file" action={config.API1Url + '/users/upload'} listType="picture" maxCount={1} multiple={false}>
                  <BaseButton type="default" icon={<UploadOutlined />}>
                    {t('forms.validationFormLabels.clickToUpload')}
                  </BaseButton>
                </BaseUpload>
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item name="status" label="Status" valuePropName="checked">
                <BaseSwitch />
              </BaseButtonsForm.Item>


            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="docs"
                label={"Upload verification documents"}
                // valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: "Please select a document"
                  }
                ]}

                getValueFromEvent={normFileDocs}
              >
                <BaseUpload name="file" action={config.API1Url + '/users/upload'} multiple={true} maxCount={4} defaultFileList={formData.docs}>
                  <BaseButton type="default" icon={<UploadOutlined />}>
                    {t('forms.validationFormLabels.clickToUpload')}
                  </BaseButton>
                </BaseUpload>
              </BaseButtonsForm.Item>
            </BaseCol>

          </BaseRow>

          <BaseRow gutter={[10, 10]}>
            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item>
                <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                  Update
                </BaseButton>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        </Form>
      }
    </div>
  );
};
