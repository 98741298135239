import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const ValidationFormClinicPatientEdit: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
const [value,setValue]=useState('Male')
  const onFinish = async (values = {}) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setFieldsChanged(false);
      notificationController.success({ message: t('common.success') });
      console.log(values);
    }, 1000);
  };

  return (

    <BaseButtonsForm
    style={{opacity:0.7,pointerEvents:'none'}}
      {...formItemLayout}
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      initialValues={{
        'input-number': 3,
        'checkbox-group': ['A', 'B'],
        rate: 3.5,
      }}
      footer={
        <BaseRow gutter={[10, 10]}>
          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <h3>Finish</h3></BaseCol>

        </BaseRow>
      }
      onFinish={onFinish}
    >

      <BaseRow gutter={[10, 10]}>


        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item
            name="firstName"
            label={"First Name"}
            rules={[{ required: true, message: "First Name is required" }]}
          >
            <BaseInput />
          </BaseButtonsForm.Item>
        </BaseCol>

        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item
            name="lastName"
            label={"Last Name"}
            rules={[{ required: true, message: "Last Name is required" }]}
          >
            <BaseInput />
          </BaseButtonsForm.Item>
        </BaseCol>


{/* 
        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item
            name="email"
            label={"Email"}
            rules={[{ required: true, type: "email", message: "Email is required and must be a valid email" }]}
          >
            <BaseInput />
          </BaseButtonsForm.Item>
        </BaseCol> */}

        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item
            name="phone"
            label={"Phone"}
            rules={[{ required: true, message: "Phone is required" },
              // {
              //   validator: (rule: any, value: string, cb: (msg?: string) => void) => {
              //     value.length < 3 ? cb("too short") : cb();
              //   }, message: "Please enter a valid 10 digit phone number"
              // }
            ]}
          >
            <BaseInput />
          </BaseButtonsForm.Item>
        </BaseCol>

        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item
            name="dob"
            label={"Date Of Birth"}
            rules={[{ required: true, message: "Date Of Birth is required" }]}
          >
            <DayjsDatePicker format="L" size="large" />
          </BaseButtonsForm.Item>
        </BaseCol>

       

        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item
            name="address"
            label={"Address"}
            rules={[{ required: true, message: "Address is required" }]}
          >
            <BaseInput />
          </BaseButtonsForm.Item>
        </BaseCol>



        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item
            name="select"
            label={"Select State"}
            hasFeedback
            rules={[{ required: true, message: "State is required" }]}
          >
            <BaseSelect placeholder={"Please select a state"}>
              <Option value="Koshi">Koshi</Option>
              <Option value="Madhesh">Madhesh</Option>
              <Option value="Bagmati">Bagmati</Option>
              <Option value="Gandaki">Gandaki</Option>
              <Option value="Lumbini">Lumbini</Option>
              <Option value="Karnali">Karnali</Option>
              <Option value="Sudurpashchim">Sudurpashchim</Option>

            </BaseSelect>
          </BaseButtonsForm.Item>
        </BaseCol>

        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item
            name="select"
            label={"Select City"}
            hasFeedback
            rules={[{ required: true, message: "City is required" }]}
          >
            <BaseSelect placeholder={"Please select a city"}>
              <Option value="abc">ABC</Option>
              <Option value="abc">ABC</Option>
              <Option value="abc">ABC</Option>
              <Option value="abc">ABC</Option>
              <Option value="abc">ABC</Option>

            </BaseSelect>
          </BaseButtonsForm.Item>
        </BaseCol>


        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item name="gender" label="Gender" valuePropName="checked">
          <BaseRadio.Group onChange={(event) => setValue(event.target.value)} value={value}>
            <BaseRadio value={'Male'}>Male</BaseRadio>
            <BaseRadio value={'Female'}>Female</BaseRadio>

          </BaseRadio.Group>
          </BaseButtonsForm.Item>


        </BaseCol>
        {/* <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item name="radio-group" label={t('forms.validationFormLabels.radioGroup')}>
            <BaseRadio.Group>
              <BaseRadio value="a">{t('forms.validationFormLabels.item')} 1</BaseRadio>
              <BaseRadio value="b">{t('forms.validationFormLabels.item')} 2</BaseRadio>
              <BaseRadio value="c">{t('forms.validationFormLabels.item')} 3</BaseRadio>
            </BaseRadio.Group>
          </BaseButtonsForm.Item>
        </BaseCol> */}
        {/* <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item name="checkbox-group" label={t('forms.validationFormLabels.checkboxGroup')}>
            <BaseCheckbox.Group>
              <BaseRow>
                <BaseCol span={8}>
                  <BaseCheckbox value="A">A</BaseCheckbox>
                </BaseCol>
                <BaseCol span={8}>
                  <BaseCheckbox value="B" disabled>
                    B
                  </BaseCheckbox>
                </BaseCol>
                <BaseCol span={8}>
                  <BaseCheckbox value="C">C</BaseCheckbox>
                </BaseCol>
                <BaseCol span={8}>
                  <BaseCheckbox value="D">D</BaseCheckbox>
                </BaseCol>
                <BaseCol span={8}>
                  <BaseCheckbox value="E">E</BaseCheckbox>
                </BaseCol>
                <BaseCol span={8}>
                  <BaseCheckbox value="F">F</BaseCheckbox>
                </BaseCol>
              </BaseRow>
            </BaseCheckbox.Group>
          </BaseButtonsForm.Item>
        </BaseCol> */}
        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item
            name="avatar"
            label={"Upload your logo"}
            valuePropName="fileList"
            rules={[
              {required:true,
              message:"Please select a logo"}
            ]}
            getValueFromEvent={normFile}
          >
            <BaseUpload name="logo" action="/upload.do" listType="picture">
              <BaseButton type="default" icon={<UploadOutlined />}>
                {t('forms.validationFormLabels.clickToUpload')}
              </BaseButton>
            </BaseUpload>
          </BaseButtonsForm.Item>
        </BaseCol>
        
      </BaseRow>


    </BaseButtonsForm>

  );
};
