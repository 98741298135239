import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

import { Card } from 'antd';
import loginBackground from '@app/assets/images/login-bg.webp';

const ThankYouPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className='asm' style={{ background: `url(${loginBackground})`, backgroundSize: 'repeat',height:'100vh',display:'flex' }}>
            <PageTitle>{'Thank You'}</PageTitle>
            <div style={{ width: '90%', margin: 'auto', padding: '30px 30px' }}>

                <Card title='Thank You'>

                    <div style={{ flexDirection: 'column' }}>
                        <h3>Thank you for using our platform.</h3>
                        <h4>We have received your request, you will hear from us soon</h4>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default ThankYouPage;
