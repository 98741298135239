import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './ForgotPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogout, doResetPassword, sendPasswordOTPAction } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';


interface ForgotPasswordFormData {
  email: string;
}

const initValues = {
  email: null,
};

export const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);



  const sendOTP = async (values: ForgotPasswordFormData) => {
    setLoading(true);


    // return;
    let args = {

      email: values.email

    }

    localStorage.setItem('emailForPass',values.email);
    dispatch(sendPasswordOTPAction(args))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp && !resp.error && !resp.Error) {
          notificationController.success({ message: t('common.success') });
          navigate('/auth/security-code');

        }
        else {
          notificationController.error({ message: 'Failed to send OTP' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })

  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={sendOTP} requiredMark="optional" initialValues={initValues}>
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('common.resetPassword')}</Auth.FormTitle>
        <S.Description>{t('forgotPassword.description')}</S.Description>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[{ required: true, message: t('common.emailError') }]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            Send OTP
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
