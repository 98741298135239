import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import config from '@app/config';
import { createInternalUserAction } from '@app/store/slices/userSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { readUser } from '@app/services/localStorage.service';
import moment from 'moment';
import { returnArea } from '@app/utils/utils';
import { Form } from 'antd';
import dayjs from 'dayjs';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const ValidationFormPatientAdd: React.FC = () => {
  const formRef=useRef<any>();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [formData, setFormData] = useState({});
  const [isLoadingForm, setLoadingForm] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState('Male')
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const statesTree = useAppSelector((state) => state.user.statesTree);
  const [districts, setDistricts] = useState([]);
  const [gaunpalikas, setGaunpalikas] = useState([]);
  const [state, setState] = useState(null);
  const [district, setDistrict] = useState(null);
  const [gaunPalika, setGaunPalika] = useState(null);
  const [formReset, setFormReset] = useState(false);


  const clinicDetails = readUser();
  if(clinicDetails!.role_id==config.roleIds.Doctor) clinicDetails!.id=clinicDetails!.clinic_id;

  console.log({ formData });



  useEffect(() => {

    setFormReset(true);
    setDistrict(null);
    setGaunPalika(null);
    setDistricts([]);
    setGaunpalikas([]);
    formRef?.current?.setFieldsValue({city:undefined});
    formRef?.current?.setFieldsValue({district:undefined});
    setDistricts(returnArea(state, null, statesTree));


    setTimeout(() => {


      setFormReset(false);

    }, 50);
  }, [state])


  useEffect(() => {

    setFormReset(!formReset);
    setGaunPalika(null);
    setGaunpalikas([]);
    formRef?.current?.setFieldsValue({city:undefined});
    setGaunpalikas(returnArea(state, district, statesTree));

    setTimeout(() => {

      setFormReset(false);

    }, 50);


  }, [district])



  useEffect(() => {


    let val = searchParams.get("data");

    let values = decodeURIComponent(val || "");
    if(values) setFormData(JSON.parse(values));

    setTimeout(() => {
      setLoadingForm(false);
    }, 400);

  }, []);


  const onFinish = async (values: any) => {
    setLoading(true);


    setFieldsChanged(false);
    values.password = config.defaultPass;
    values.confirmPassword = config.defaultPass;
    values.gender = value;
    values.user_type = 2;
    values.role_id = config.roleIds['Client'];
    values.clinic_id = clinicDetails?.id;
    typeof (values.status) !== 'boolean' ? values.status = false : values.status = values.status;

    console.log(values);

    dispatch(createInternalUserAction(values))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp.success) {
          notificationController.success({ message: t('common.success') });
          navigate('/company/patients/list');
        }
        else {
          notificationController.error({ message: 'Failed to save patient info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })

  };

  if (formData) {


    if (formData && 'dob' in formData) {
      formData["dob"] = dayjs(formData["dob"] as string || new Date())
    }

  }
  return (
    <>
    
      {!isLoadingForm && 
        <Form
          {...formItemLayout}
          ref={formRef}

          name="validateForm"
          initialValues={formData}
          
          onFinish={onFinish}
        >

          <BaseRow gutter={[10, 10]}>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="firstName"
                label={"First Name"}
                rules={[{ required: true, message: "First Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="lastName"
                label={"Last Name"}
                rules={[{ required: true, message: "Last Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>



            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="email"
                label={"Email"}
                rules={[{ required: true, type: "email", message: "Email is required and must be a valid email" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="userName"
                label={"Username"}
                // rules={[{ required: true, message: "Username is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="phone"
                label={"Phone"}
                rules={[{ required: true, message: "Phone is required" },
                  // {
                  //   validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                  //     value.length < 3 ? cb("too short") : cb();
                  //   }, message: "Please enter a valid 10 digit phone number"
                  // }
                ]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="dob"
                label={"Date Of Birth"}
                rules={[{ required: true, message: "Date Of Birth is required" }]}

              >
                <DayjsDatePicker format="L" size="large" />
              </BaseButtonsForm.Item>
            </BaseCol>



            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="address"
                label={"Address"}
                // rules={[{ required: true, message: "Address is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="pincode"
                label={"Pincode"}
                // rules={[{ required: true, message: "Pincode is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="state"
                label={"Select State"}
                hasFeedback
                // rules={[{ required: true, message: "State is required" }]}
                initialValue={state}
              >
                <BaseSelect placeholder={"Please select a state"} onChange={(e: any) => setState(e)}>
                  {statesTree && statesTree.map((item: any) =>
                    <Option value={item?.id}>{item?.name}</Option>

                  )}

                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="district"
                label={"Select District"}
                hasFeedback
                // rules={[{ required: true, message: "District is required" }]}
                initialValue={district}

              >
                <BaseSelect placeholder={"Please select a district"} onChange={(e: any) => setDistrict(e)}>
                  {districts && districts.map((item: any) =>
                    <Option value={item?.id}>{item?.district_name}</Option>

                  )}


                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="city"
                label={"Select Gaunpalika"}
                hasFeedback
                // rules={[{ required: true, message: "Gaunpalika is required" }]}
                initialValue={gaunPalika}
              >
                <BaseSelect placeholder={"Please select a gaunpalika"} onChange={(e: any) => setGaunPalika(e)}>
                  {gaunpalikas && gaunpalikas.map((item: any) =>
                    <Option value={item?.id}>{item?.gaunpalika_name}</Option>

                  )}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item name="gender" label="Gender" valuePropName="checked">
                <BaseRadio.Group onChange={(event) => setValue(event.target.value)} value={value}>
                  <BaseRadio value={'Male'}>Male</BaseRadio>
                  <BaseRadio value={'Female'}>Female</BaseRadio>

                </BaseRadio.Group>
              </BaseButtonsForm.Item>


            </BaseCol>
            {/* <BaseCol xs={12} sm={8} md={12} xl={6}>
  <BaseButtonsForm.Item name="radio-group" label={t('forms.validationFormLabels.radioGroup')}>
    <BaseRadio.Group>
      <BaseRadio value="a">{t('forms.validationFormLabels.item')} 1</BaseRadio>
      <BaseRadio value="b">{t('forms.validationFormLabels.item')} 2</BaseRadio>
      <BaseRadio value="c">{t('forms.validationFormLabels.item')} 3</BaseRadio>
    </BaseRadio.Group>
  </BaseButtonsForm.Item>
</BaseCol> */}
            {/* <BaseCol xs={12} sm={8} md={12} xl={6}>
  <BaseButtonsForm.Item name="checkbox-group" label={t('forms.validationFormLabels.checkboxGroup')}>
    <BaseCheckbox.Group>
      <BaseRow>
        <BaseCol span={8}>
          <BaseCheckbox value="A">A</BaseCheckbox>
        </BaseCol>
        <BaseCol span={8}>
          <BaseCheckbox value="B" disabled>
            B
          </BaseCheckbox>
        </BaseCol>
        <BaseCol span={8}>
          <BaseCheckbox value="C">C</BaseCheckbox>
        </BaseCol>
        <BaseCol span={8}>
          <BaseCheckbox value="D">D</BaseCheckbox>
        </BaseCol>
        <BaseCol span={8}>
          <BaseCheckbox value="E">E</BaseCheckbox>
        </BaseCol>
        <BaseCol span={8}>
          <BaseCheckbox value="F">F</BaseCheckbox>
        </BaseCol>
      </BaseRow>
    </BaseCheckbox.Group>
  </BaseButtonsForm.Item>
</BaseCol> */}


          </BaseRow>

          <BaseRow gutter={[10, 10]}>
            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item>
                <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                  {t('common.submit')}
                </BaseButton>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        </Form>
      }
    </>
  );
};
