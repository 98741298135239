import { createLabTestData, createNotesData, createPatientData, createPrescriptionsData, createVitalsData, getLabTestByName, getMedicineByName, getPatientData, updateLabTestData, updateNotesData, updatePrescriptionsData, updateVitalsData } from '@app/apis/patient-data-api';
import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';

export interface UserState {
    patients: any | null;
}

const initialState: UserState = {
    patients: {},
};

export const createPatientDataAction = createAsyncThunk('patient/createDate', async (payload: any, { dispatch }) =>
    createPatientData(payload).then(async (res: any) => {

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);


export const getPatientDataAction = createAsyncThunk('patient/getData', async ({ patient_id, clinic_id }: { patient_id: number, clinic_id: number }) =>

    getPatientData(patient_id, clinic_id).then(async (res: any) => {

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);


export const createVitalsAction = createAsyncThunk('patient/createVitals', async (payload: any, { dispatch }) =>
    createVitalsData(payload).then(async (res: any) => {

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);



export const updateVitalsAction = createAsyncThunk('patient/updateVitals', async ({ id, payload }: { id: number, payload: any }) =>
    updateVitalsData(id, payload).then(async (res: any) => {

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);


export const createClinicNotesAction = createAsyncThunk('patient/clinicNotesCreate', async (payload: any, { dispatch }) =>
    createNotesData(payload).then(async (res: any) => {

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);

export const updateNotesAction = createAsyncThunk('patient/updateVitals', async ({ id, payload }: { id: number, payload: any }) =>
    updateNotesData(id, payload).then(async (res: any) => {

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);

export const createPrescriptionsAction = createAsyncThunk('patient/clinicPrescriptionsCreate', async ({ id, payload }: { id: number, payload: any }) =>
    createPrescriptionsData(id,payload).then(async (res: any) => {

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);

export const updatePrescriptionsAction = createAsyncThunk('patient/PrescriptionsUpdate', async ({ id, payload }: { id: number, payload: any }) =>
    updatePrescriptionsData(id, payload).then(async (res: any) => {

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);

export const createLabTestAction = createAsyncThunk('patient/createLabTest', async ({ id, payload }: { id: number, payload: any }) =>
    createLabTestData(id,payload).then(async (res: any) => {

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);

export const updateLabTestAction = createAsyncThunk('patient/labTestUpdate', async ({ id, payload }: { id: number, payload: any }) =>
    updateLabTestData(id, payload).then(async (res: any) => {

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);

export const getMedicineByNameAction = createAsyncThunk('miscellaneous/medicines', async ({ name }: { name: string }) =>
    getMedicineByName(name).then(async (res: any) => {

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);


export const getLabTestsByNameAction = createAsyncThunk('miscellaneous/getLabTestsByNameAction', async ({ name }: { name: string }) =>
    getLabTestByName(name).then(async (res: any) => {

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);

export const patientReducer = createSlice({
    name: 'patient',
    initialState,
    reducers: {}
});

export default patientReducer.reducer;
