import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import MyPatientsList from '@app/components/manage-my-patients/list';

const ManageMyPatients: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>Manage My Patients</PageTitle>
      <MyPatientsList />
    </>
  );
};

export default ManageMyPatients;
