import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { BaseButton } from '../../../../common/BaseButton/BaseButton';
import { Modal } from 'antd';
import { ValidationFormVitalsEdit } from './ValidationFormVitalsEdit';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';

interface EditVitalsProps {
    setEditType: (editType: string) => void;
    editType: String,
    currentData:any,
    getPatientDataAll:any
}

const EditVitalsModal: React.FC<EditVitalsProps> = (props) => {
    const { setEditType, editType } = props;
    const { t } = useTranslation();
    let navigate = useNavigate();
    const formRef = useRef<any>();
    return (



        <BaseModal getContainer={false} size={'large'} {...props} open={editType == 'vitals'} onCancel={() => setEditType('')} onOk={()=>setEditType('') } footer={<></>}>

            <h3 style={{marginBottom:'20px',borderBottom:'1px solid grey'}}>Vital Signs</h3>

            <ValidationFormVitalsEdit
                formRef={formRef}
                currentData={props.currentData}
                setEditType={props.setEditType}
                getPatientDataAll={props.getPatientDataAll}
            />
        </BaseModal>

    );
};

export default EditVitalsModal;
