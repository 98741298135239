import React, { useRef, useState } from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import Modal from 'antd/lib/modal/Modal';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { Button, Divider, Input, InputRef, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { SendInvitationEmailsAction } from '@app/store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export const InvitationEmailsModal: React.FC<any> = (props) => {
    let index = 0;
    const { t } = useTranslation();
    const [items, setItems] = useState<any[]>([]);
    const [name, setName] = useState('');
    const inputRef = useRef<InputRef>(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const onNameChange = (event: any) => {
        setName(event.target.value);
    };



    const addItem = (e: any) => {
        e.preventDefault();
        if (!String(name)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
            notificationController.warning({ message: 'Please enter a valid email' });
            return
        }
        setItems([...items, name || `New item ${index++}`]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };


    const onFinish = () => {

        let emails = items.join(',');

        console.log({ emails })


        let args = {};


        if (props.user!.user_type == 1) {
            args = {
                firstName: 'ClinicsNepal',
                lastName: '',
                emails: emails,
                type: 'admin'
            }
        }
        else {
            args = {
                firstName: props.user.firstName,
                lastName: props.user.lastName,
                emails: emails,
                type: 'clinic',
                clinicId: props.user.id
            }

        }

        setIsLoading(true);
        dispatch(SendInvitationEmailsAction(args))
            .unwrap()
            .then((resp: any) => {
                setIsLoading(false);
                if (resp.messageId) {
                    notificationController.success({ message: t('common.success') });
                    props.setInvitationModal(false)
                }
                else {
                    notificationController.error({ message: 'Failed to send emails' });
                }

            }).catch((err: any) => {
                setIsLoading(false);
                notificationController.error({ message: err.message });
            })

    }
    return (
        <Modal
            open={props.open}
            onCancel={() => props.setInvitationModal(false)}
            footer={false}

        >

            <BaseCard title='Send bulk invitations' bodyStyle={{ paddingLeft: '5px' }}>

                <BaseRow gutter={[10, 10]}>
                    <BaseCol xs={24} sm={24} md={24} xl={24}>
                        <BaseButtonsForm.Item>
                            <Select
                                mode='multiple'
                                style={{
                                    width: 300,
                                }}
                                placeholder="Email Addresses"
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <Space
                                            style={{
                                                padding: '0 8px 4px',
                                            }}
                                        >
                                            <Input
                                                placeholder="Please enter email"
                                                ref={inputRef}
                                                value={name}
                                                onChange={onNameChange}
                                                onKeyDown={(e) => e.stopPropagation()}
                                            />
                                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                Add item
                                            </Button>
                                        </Space>
                                    </>
                                )}
                                options={items.map((item) => ({
                                    label: item,
                                    value: item,
                                }))}
                            />

                        </BaseButtonsForm.Item>
                    </BaseCol>
                </BaseRow>


                <BaseRow gutter={[10, 10]}>
                    <BaseCol xs={12} sm={8} md={12} xl={6}>
                        <BaseButtonsForm.Item>
                            <BaseButton type="primary" htmlType="submit" loading={isLoading} onClick={onFinish}>
                                Submit
                            </BaseButton>
                        </BaseButtonsForm.Item>
                    </BaseCol>
                </BaseRow>

            </BaseCard>
        </Modal>
    )
};
