import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import MedicalDashboardPage from '@app/pages/DashboardPages/MedicalDashboardPage';
import OTPCodePage from '@app/pages/OTPCodePage';


import ManageUsers from '@app/pages/ManageUsers';
import AddUser from '../manage-internal-users/add-user';
import EditUser from '../manage-internal-users/edit-user';


import ManageEmployees from '@app/pages/ManageEmployees';


import ClinicsPage from '@app/pages/ClinicsPages/ManageClinics';
import AddClinic from '../manage-clinics/add-clinic';
import EditClinic from '../manage-clinics/edit-clinic';
import CompanyDashboard from '@app/pages/ExternalDashboard/CompanyDashboard';

import ClinicsPatientsPage from '@app/pages/ClinicsPages/ManageClinicPatients';
import { ValidationFormClinicPatientEdit } from '../manage-clinics/ManageClinicPatients/ValidationFormEdit/ValidationForm';
import ViewClinicPatient from '../manage-clinics/ManageClinicPatients/view-customer';

import MyPatientsList from '../manage-my-patients/list';
import ManageMyPatients from '@app/pages/ExternalDashboard/MyPatientsPages/ManageMyPatients';
import AddMyPatient from '@app/pages/ExternalDashboard/MyPatientsPages/AddMyPatient';
import EditMyPatients from '@app/pages/ExternalDashboard/MyPatientsPages/EditMyPatient';
import ManageMyDoctors from '@app/pages/ExternalDashboard/MyDoctorsPages/ManageMyDoctors';
import AddMyDoctor from '@app/pages/ExternalDashboard/MyDoctorsPages/AddMyDoctor';
import EditMyDoctor from '@app/pages/ExternalDashboard/MyDoctorsPages/EditMyDoctor';
import PatientChartList from '@app/pages/ExternalDashboard/MyPatientsPages/ManageCharts/PatientChartList';
import AddEmployee from '../manage-my-employees/add-user';
import EditEmployee from '../manage-my-employees/edit-user';
import { readUser } from '@app/services/localStorage.service';
import ProfileLayoutExternal from '../profile_external/ProfileLayout';
import { PersonalInfoExternal } from '../profile_external/profileCard/profileFormNav/nav/PersonalInfo/PersonalInfo';
import ManageMyAppointments from '@app/pages/ExternalDashboard/ClinicAppointments/ManageAppointments';
import AddMyAppointment from '@app/pages/ExternalDashboard/ClinicAppointments/AddAppointment';
import EditMyAppointment from '@app/pages/ExternalDashboard/ClinicAppointments/EditAppointment';
import ManageFacility from '@app/pages/FacilitiesPages/ManageFacility';
import AddFacility from '@app/pages/FacilitiesPages/AddFacility';
import EditFacility from '@app/pages/FacilitiesPages/EditFacility';
import ManageMedicine from '@app/pages/MedicinesPages/ManageMedicine';
import AddMedicine from '@app/pages/MedicinesPages/AddMedicine';
import EditMedicine from '@app/pages/MedicinesPages/EditMedicine';
import EditOtherInfo from '@app/pages/OtherInfoPages/EditOtherInfo';
import ManageTestimonial from '@app/pages/TestimonialsPages/ManageTestimonial';
import AddTestimonial from '@app/pages/TestimonialsPages/AddTestimonial';
import EditTestimonial from '@app/pages/TestimonialsPages/EditTestimonial';
import ClinicsOnboardPage from '@app/pages/ClinicOnboardPages/ClinicOnboard';
import ThankYouPage from '@app/pages/ThankYouPage/ThankYouPage';
import DoctorOnboardPage from '@app/pages/DoctorOnboardPages/DoctorOnboardPage';
import EditAvailabilityClinic from '@app/pages/ManageAppointmentClinic/EditAvailabilityClinic';
import OtherInfoPage from '@app/pages/OtherInfo';
import LabTestsList from '../manage-lab-tests/list';
import AddLabTest from '@app/pages/LabTestsPages/AddMedicine';
import EditLabTest from '@app/pages/LabTestsPages/EditMedicine';

const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/admin/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const MedicalDashboard = withLoading(MedicalDashboardPage);
const NftDashboard = withLoading(NftDashboardPage);
const NewsFeed = withLoading(NewsFeedPage);
const AdvancedForm = withLoading(AdvancedFormsPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

const DataTables = withLoading(DataTablesPage);
const Charts = withLoading(ChartsPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
let user = readUser();
export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        {/*START OF PROTECTED ROUTES           */}
        {user?.user_type == 2 &&
          <Route path="/company" element={protectedLayout}>

            <Route index element={<CompanyDashboard />} />
            <Route path="profile" element={<ProfileLayoutExternal />}>
              <Route path="personal-info" element={<PersonalInfoExternal />} />
              <Route path="security-settings" element={<SecuritySettings />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="payments" element={<Payments />} />
              <Route path="other-info-profile" element={<OtherInfoPage />} />
            </Route>

            <Route path="employees">
              <Route path="list" element={<ManageEmployees />} />
              <Route path="add" element={<AddEmployee />} />
              <Route path="edit/:id" element={<EditEmployee />} />
            </Route>

            <Route path="appointments">
              <Route path="list" element={<ManageMyAppointments />} />
              <Route path="add" element={<AddMyAppointment />} />
              <Route path="edit/:id" element={<EditMyAppointment />} />
            </Route>


            <Route path="patients">
              <Route path="list" element={<ManageMyPatients />} />
              <Route path="add" element={<AddMyPatient />} />
              <Route path="edit/:id" element={<EditMyPatients />} />

              <Route path="charts">
                <Route path="list" element={<PatientChartList />} />


              </Route>
            </Route>


            <Route path="doctors">
              <Route path="list" element={<ManageMyDoctors />} />
              <Route path="add" element={<AddMyDoctor />} />
              <Route path="edit/:id" element={<EditMyDoctor />} />
            </Route>

            <Route path="availability">

              <Route path="edit/:id" element={<EditAvailabilityClinic />} />
            </Route>

          </Route>
        }
        {user?.user_type == 1 &&
          <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
            <Route index element={<NftDashboard />} />
            {/* <Route path={MEDICAL_DASHBOARD_PATH} element={<MedicalDashboard />} /> */}



            <Route path="apps">
              <Route path="feed" element={<NewsFeed />} />
            </Route>
            <Route path="users">
              <Route path="list" element={<ManageUsers />} />
              <Route path="add" element={<AddUser />} />
              <Route path="edit/:id" element={<EditUser />} />
            </Route>

            <Route path="clinics">
              <Route path="list" element={<ClinicsPage />} />
              <Route path="add" element={<AddClinic />} />
              <Route path="edit/:id" element={<EditClinic />} />

              <Route path="patients">
                <Route path="list" element={<ClinicsPatientsPage />} />
                <Route path="view/:id" element={<ViewClinicPatient />} />
              </Route>


            </Route>

            <Route path="facilities">
              <Route path="list" element={<ManageFacility />} />
              <Route path="add" element={<AddFacility />} />
              <Route path="edit/:id" element={<EditFacility />} />
            </Route>

            <Route path="medicines">
              <Route path="list" element={<ManageMedicine />} />
              <Route path="add" element={<AddMedicine />} />
              <Route path="edit/:id" element={<EditMedicine />} />
            </Route>

            <Route path="labtests">
              <Route path="list" element={<LabTestsList />} />
              <Route path="add" element={<AddLabTest />} />
              <Route path="edit/:id" element={<EditLabTest />} />
            </Route>


            <Route path="testimonial">
              <Route path="list" element={<ManageTestimonial />} />
              <Route path="add" element={<AddTestimonial />} />
              <Route path="edit/:id" element={<EditTestimonial />} />
            </Route>

            <Route path="otherinfo">

              <Route path="edit/:id" element={<EditOtherInfo />} />
            </Route>



            <Route path="forms">
              <Route path="advanced-forms" element={<AdvancedForm />} />
            </Route>
            <Route path="data-tables" element={<DataTables />} />
            <Route path="charts" element={<Charts />} />
            <Route path="maps">
              <Route path="google-maps" element={<Google />} />
              <Route path="leaflet-maps" element={<Leaflet />} />
              <Route path="react-simple-maps" element={<ReactSimple />} />
              <Route path="pigeon-maps" element={<Pigeons />} />
            </Route>
            <Route path="server-error" element={<ServerError />} />
            <Route path="404" element={<Error404 />} />
            <Route path="profile" element={<ProfileLayout />}>
              <Route path="personal-info" element={<PersonalInfo />} />
              <Route path="security-settings" element={<SecuritySettings />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="payments" element={<Payments />} />


            </Route>
            <Route path="ui-components">
              <Route path="button" element={<Buttons />} />
              <Route path="spinner" element={<Spinners />} />
              <Route path="input" element={<Inputs />} />
              <Route path="checkbox" element={<Checkboxes />} />
              <Route path="radio" element={<Radios />} />
              <Route path="select" element={<Selects />} />
              <Route path="switch" element={<Switches />} />
              <Route path="upload" element={<Uploads />} />
              <Route path="rate" element={<Rates />} />
              <Route path="auto-complete" element={<AutoCompletes />} />
              <Route path="steps" element={<Steps />} />
              <Route path="date-time-picker" element={<DateTimePickers />} />
              <Route path="dropdown" element={<Dropdowns />} />
              <Route path="breadcrumbs" element={<Breadcrumbs />} />
              <Route path="tabs" element={<Tabs />} />
              <Route path="avatar" element={<Avatars />} />
              <Route path="badge" element={<Badges />} />
              <Route path="collapse" element={<Collapse />} />
              <Route path="pagination" element={<Pagination />} />
              <Route path="modal" element={<Modals />} />
              <Route path="popover" element={<Popovers />} />
              <Route path="popconfirm" element={<Popconfirms />} />
              <Route path="progress" element={<Progress />} />
              <Route path="result" element={<Results />} />
              <Route path="alert" element={<Alerts />} />
              <Route path="notification" element={<NotificationsUI />} />
              <Route path="skeleton" element={<Skeletons />} />
            </Route>
          </Route>
        }
        {/*END OF PROTECTED ROUTES           */}
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />

          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="otp-code" element={<OTPCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="clinic-onboard" element={<ClinicsOnboardPage />} />
        <Route path="doctor-onboard" element={<DoctorOnboardPage />} />
        <Route path="thank-you" element={<ThankYouPage />} />
        <Route path="/logout" element={<LogoutFallback />} />

        <Route path="/" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
};
