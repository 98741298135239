import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import ClinicPatientsList from '@app/components/manage-clinics/ManageClinicPatients/list';

const ClinicsPatientsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>View Clinic Patients</PageTitle>
      <ClinicPatientsList />
    </>
  );
};

export default ClinicsPatientsPage;
