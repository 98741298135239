import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { UserTypes } from '@app/config';
import { readUser } from '@app/services/localStorage.service';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();
  let user = readUser();
  let nav = '/admin/profile';
  if (user?.user_type == 2) {
    nav = '/company/profile'
  }
  else {

  }

  return (
    <div {...props}>
      <S.Text>
        <Link to={nav}>{t('profile.title')}</Link>
      </S.Text>
      <S.ItemsDivider />
      <S.Text>
        <Link to="/logout">{t('header.logout')}</Link>
      </S.Text>
    </div>
  );
};
