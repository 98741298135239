import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import config from '@app/config';
import { createFacilityAction, createInternalUserAction, createMedicineAction } from '@app/store/slices/userSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';


const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};



export const ValidationFormMedicineAdd: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [formData, setFormData] = useState({isActive:true});
  const [isLoadingForm, setLoadingForm] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fileId, setFileId] = useState(null);
  const { t } = useTranslation();
  const [value, setValue] = useState('Male')
  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const normFile = (e: any) => {
    console.log({ e })
    if (e.file.status == 'done') {
      console.log('e.file?.response?.file?.file_id', e.file?.response?.file?.file_id);
      setFileId(e.file?.response?.file?.file_id);
    }
    if (Array.isArray(e.fileList)) {
      return e.fileList;
    }

    return e && e.fileList;
  };

  const onFinish = async (values: any) => {
    setLoading(true);


    setFieldsChanged(false);

    values.file_id=fileId;
    console.log(values);

    dispatch(createMedicineAction(values))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp && !resp.error) {
          notificationController.success({ message: t('common.success') });
          navigate('/admin/medicines/list');
        }
        else {
          notificationController.error({ message: 'Failed to save info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })

  };

  return (
    <>

      {!isLoadingForm &&
        <BaseButtonsForm
          {...formItemLayout}
          isFieldsChanged={isFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          name="validateForm"
          initialValues={formData}
          footer={
            // <BaseRow gutter={[10, 10]}>
            //   <BaseCol xs={12} sm={8} md={12} xl={6}>
            //     <h3>Finish</h3></BaseCol>

            // </BaseRow>
            <></>
          }
          onFinish={onFinish}
        >

          <BaseRow gutter={[10, 10]}>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="Code"
                label={"Code"}
                rules={[{ required: true, message: "Code is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="Brand_Name"
                label={"Brand Name"}
                rules={[{ required: true, message: "Brand Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="Generic_Name"
                label={"Generic Name"}
                rules={[{ required: true, message: "Generic Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="MF_Name"
                label={"MF_Name"}
                rules={[{ required: true, message: "MF_Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item name="isActive" label="Status" valuePropName="checked" initialValue={true}>
                <BaseSwitch defaultChecked={true}/>
              </BaseButtonsForm.Item>


            </BaseCol>
          </BaseRow>

          <BaseRow gutter={[10, 10]}>
            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item>
                <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                  {t('common.submit')}
                </BaseButton>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseButtonsForm>
      }
    </>
  );
};
