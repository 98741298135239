import React from 'react';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { PasswordForm } from '../../../../../profile/profileCard/profileFormNav/nav/SecuritySettings/passwordForm/PasswordForm/PasswordForm';
import { TwoFactorAuth } from '../../../../../profile/profileCard/profileFormNav/nav/SecuritySettings/twoFactorAuth/TwoFactorAuth';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { OtherInfoFOrm } from './OtherInfoForm';

export const OtherInfo: React.FC = () => (
  <BaseCard>
    <BaseRow gutter={[30, 0]}>
    
        <OtherInfoFOrm />
  

    </BaseRow>
  </BaseCard>
);
