import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ValidationFormPatientAdd } from '@app/components/manage-my-patients/ValidationFormAdd/ValidationForm';
import ManageChartsList from '@app/components/manage-my-patients/manage-charts/list';

const PatientChartList: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <PageTitle>Manage Patient Chart</PageTitle>
            {/* <h4 style={{fontWeight:'bold'}}>Add New Chart</h4> */}
            <ManageChartsList

            />
        </>
    );
};

export default PatientChartList;
