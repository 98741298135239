import React, { useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { References } from '@app/components/common/References/References';
import { useResponsive } from '@app/hooks/useResponsive';
import { TrendingCreators } from '@app/components/nft-dashboard/trending-creators/TrendingCreators';
import { RecentlyAddedNft } from '@app/components/nft-dashboard/recently-added/RecentlyAddedNft';
import { TrendingCollections } from '@app/components/nft-dashboard/trending-collections/TrendingCollections';
import { Balance } from '@app/components/nft-dashboard/Balance/Balance';
import { TotalEarning } from '@app/components/nft-dashboard/totalEarning/TotalEarning';
import { StatsSection } from '@app/components/nft-dashboard/statsSection/ActivityStory';
import { RecentActivity } from '@app/components/nft-dashboard/recentActivity/RecentActivity';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { RecentAddedClients } from '@app/components/nft-dashboard/recent-clients/RecentClients';
import { getAdminDashboardInfoAction } from '@app/store/slices/userSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { Spin } from 'antd';
import config from '@app/config';

const MedicalDashboardPage: React.FC = () => {
  const { isDesktop } = useResponsive();
  const [adminDashDetails, setAdminDashDetails] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {

    dispatch(getAdminDashboardInfoAction())
      .unwrap()
      .then((resp) => {

        if (resp.doctors) {

          resp.doctors.map((x: any) => {
            x.header = { heading: x.firstName + ' ' + x.lastName, profileImage: config.API1Url + '/' + x.image?.url };

          })

        }
        console.log({ resp })

        resp.stats = [
          { title: 'Total Clinics', total: resp.clinicCounts }, { title: 'Total Clients', total: resp.clientsCount }, { title: 'Total Doctors', total: resp.doctorsCount }
        ]
        setAdminDashDetails(resp);
        setLoading(false);



      }).catch((err) => {
        notificationController.error({ message: err.message });
      })

  }, []);

  const desktopLayout = (
    <BaseRow>
      {/* <Spin spinning={loading}> */}
      <S.LeftSideCol xl={16} xxl={17} id="desktop-content">
        <BaseRow gutter={[60, 60]}>
          <BaseCol span={24}>
            {adminDashDetails && <TrendingCreators adminDashDetails={adminDashDetails} />}
          </BaseCol>

          <BaseCol span={24}>
            {adminDashDetails && <RecentlyAddedNft adminDashDetails={adminDashDetails} />}
          </BaseCol>

          <BaseCol span={24}>
            {adminDashDetails && <RecentAddedClients adminDashDetails={adminDashDetails} />}
          </BaseCol>

          {/* <BaseCol span={24}>
            <RecentActivity />
          </BaseCol> */}
        </BaseRow>
        {/* <References /> */}
      </S.LeftSideCol>

      <S.RightSideCol xl={8} xxl={7}>
        {/* <div id="balance">
          <Balance />
        </div>
        <S.Space />
        <div id="total-earning">
          <TotalEarning />
        </div> */}
        <S.Space />
        <S.ScrollWrapper id="activity-story">
          <StatsSection adminDashDetails={adminDashDetails} />
        </S.ScrollWrapper>
      </S.RightSideCol>
      {/* </Spin> */}
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <TrendingCreators adminDashDetails={adminDashDetails} />
      </BaseCol>

      <BaseCol span={24}>
        <RecentlyAddedNft adminDashDetails={adminDashDetails} />
      </BaseCol>

      <BaseCol span={24}>
        <TrendingCollections adminDashDetails={adminDashDetails} />
      </BaseCol>

      {/* <BaseCol span={24}>
        <RecentActivity />
      </BaseCol> */}
    </BaseRow>
  );

  return (
    <>
      <PageTitle>NFT Dashboard</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default MedicalDashboardPage;
