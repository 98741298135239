import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { BaseButton } from '../../../../common/BaseButton/BaseButton';
import { Modal } from 'antd';
import { ValidationFormPrescriptionEdit } from './ValidationFormPrescriptionEdit';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { PayCircleOutlined } from '@ant-design/icons';

interface EditPrescriptionsProps {
    setEditType: (editType: string) => void;
    editType: String,
    currentData: any,
    getPatientDataAll: any,
    patientDataId:any,

}

const EditPrescriptionsModal: React.FC<EditPrescriptionsProps> = (props) => {
    const { setEditType, editType } = props;
    const { t } = useTranslation();
    let navigate = useNavigate();
    const formRef = useRef<any>();
    return (



        <BaseModal getContainer={false} size={'large'} {...props} open={editType == 'prescriptions'} onCancel={() => setEditType('')} onOk={()=>setEditType('') } footer={<></>}>

            <h3 style={{marginBottom:'20px',borderBottom:'1px solid grey'}}>Prescriptions</h3>

            <ValidationFormPrescriptionEdit
                formRef={formRef}
                getPatientDataAll={props.getPatientDataAll}
                patientDataId={props.patientDataId}
                currentData={props.currentData}
                setEditType={props.setEditType}
            />
        </BaseModal>

    );
};

export default EditPrescriptionsModal;
