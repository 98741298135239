import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { getMedicineByNameAction, updatePrescriptionsAction } from '@app/store/slices/patientDataSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Select, Spin } from 'antd';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const ValidationFormPrescriptionEdit: React.FC<any> = (props) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [medicines, setMedicines] = useState([]);
  const [prescriptions, setPrescriptions] = useState<any[]>(props.currentData);
  const dispatch = useAppDispatch();


console.log({prescriptions})

  const onFinish = async (values = {}) => {
    setLoading(true);
    console.log({ prescriptions })
    dispatch(updatePrescriptionsAction({ id: props.patientDataId, payload: prescriptions }))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp && !resp.error && !resp.Error) {
          notificationController.success({ message: t('common.success') });
          props.setEditType('');
          props.getPatientDataAll();
        }
        else {
          notificationController.error({ message: 'Failed to save patient info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })
  };

  const onUserInput = (e: string) => {

    console.log(e)
    if (e.length < 3) return;
    dispatch(getMedicineByNameAction({ name: e }))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp && !resp.error && !resp.Error) {
          setMedicines(resp);
        }
        else {
          notificationController.error({ message: 'Failed to get medicines info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })
  }


  const handleOnChange = (val: any, type: string, idx: number) => {

    let _prescriptions = [...prescriptions];

    _prescriptions[idx][type] = val;

    setPrescriptions(_prescriptions);
    console.log('wewewewewewe', val);
  }


  return (


    <BaseButtonsForm
      ref={props.formRef}
      // style={{ pointerEvents: 'none', opacity: '0.7' }}
      {...formItemLayout}
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      // initialValues={{
      //   'input-number': 3,
      //   'checkbox-group': ['A', 'B'],
      //   rate: 3.5,
      // }}
      footer={
        <></>
      }
      onFinish={onFinish}
    >

      {prescriptions?.map((item, idx) =>


        <BaseRow gutter={[10, 10]}>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name={"drugName" + idx}
              label={"Drug Name"}
              initialValue={item.drugName}
              // rules={[{ required: true, message: "Drug Name is required" }]}
            >
              <BaseInput disabled defaultValue={item.drugName} onChange={(e) => handleOnChange(e.target.value, 'drugName', idx)} />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item

              name={"dosage" + idx}
              label={"Dosage (units)"}
              initialValue={item.dosage}

              // rules={[{ required: true, message: "Dosage is required" }]}
            >
              < InputNumber  defaultValue={item.tabPerDose} onChange={(e) => handleOnChange(e, 'dosage', idx)} />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item

              name={"frequency" + idx}
              label={"Frequency (times)"}
              initialValue={item.frequency}

              // rules={[{ required: true, message: "Frequency is required" }]}
            >
              <BaseInput defaultValue={item.frequency} onChange={(e) => handleOnChange(e.target.value, 'frequency', idx)} />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item

              name={"duration" + idx}
              label={"Duration"}
              initialValue={item.duration}
              // rules={[{ required: true, message: "Duration is required" }]}
            >
              <BaseInput defaultValue={item.duration} onChange={(e) => handleOnChange(e.target.value, 'duration', idx)} />
            </BaseButtonsForm.Item>
          </BaseCol>



        </BaseRow>

      )}

      <BaseRow gutter={[10, 10]}>


        <BaseButtonsForm.Item
          style={{ width: '300px' }}
          // name="addDrug"
          label={'Select Drug To Add'}
          hasFeedback
        // rules={[{ required: true, message: t('forms.validationFormLabels.countryError') }]}
        >
          <Select
            value={value}
            showSearch
            labelInValue
            filterOption={false}
            onChange={(e: any) => { setPrescriptions([...prescriptions, { drugName: e!.value }]); setValue(null); }}

            placeholder="Enter medicine name to search"
            onSearch={(evt) => onUserInput(evt)}
            // options={users}
            notFoundContent={isLoading ? <Spin size="small" /> : <span>No results</span>}
          >
            {medicines && (medicines || []).length > 0 && medicines.map(function (item: any) {
              return (
                <Select.Option key={item.id} value={item.Brand_Name}>
                  {item.Brand_Name}
                </Select.Option>
              );
            })}

          </Select>
          {/* <BaseSelect onChange={(e) => { setPrescriptions([...prescriptions, { drugName: e }]) }}>
    <Option value="Paracip 500">{"Paracip 500"}</Option>
    <Option value="Dolo">{"Dolo"}</Option>
    <Option value="Brufen">{"Brufen"}</Option>
    <Option value="Benadryl">{"Benadryl"}</Option>
    <Option value="XYZ">{"XYZ"}</Option>
  </BaseSelect> */}
        </BaseButtonsForm.Item>
      </BaseRow>
      <BaseRow gutter={[10, 10]}>
        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item>
            <BaseButton type="primary" htmlType="submit" loading={isLoading}>
              Update
            </BaseButton>
          </BaseButtonsForm.Item>
        </BaseCol>
      </BaseRow>

    </BaseButtonsForm>

  );
};
