import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import ClinicsList from '@app/components/manage-clinics/list';
import AddClinic from '@app/components/manage-clinics/add-clinic';
import { ValidationFormClinicAdd } from '@app/components/manage-clinics/ValidationFormAdd/ValidationForm';
import { Card } from 'antd';
import loginBackground from '@app/assets/images/login-bg.webp';
import { getStatesTreeAction } from '@app/store/slices/userSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
const ClinicsOnboardPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {

        dispatch(getStatesTreeAction())
            .unwrap()
            .then((resp) => {


            }).catch((err) => {
                notificationController.error({ message: err.message || 'Something went wrong' });
            })


    }, [])
    return (
        <div className='asm' style={{ background: `url(${loginBackground})`, backgroundSize: 'repeat' }}>
            <PageTitle>{'Clinic Onboard'}</PageTitle>
            <div style={{ width: '90%', margin: 'auto', padding: '30px 30px' }}>

                <Card title='Clinic Onboard'>



                    <ValidationFormClinicAdd outer={true} />
                </Card>
            </div>
        </div>
    );
};

export default ClinicsOnboardPage;
