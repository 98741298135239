import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { BaseButton } from '../../../../common/BaseButton/BaseButton';
import { Modal } from 'antd';
import { ValidationFormCompliantsEdit } from './ValidationFormNotesEdit';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { ValidationFormNotesAdd } from './ValidationFormNotesAdd';

interface EditVitalsProps {
    setAddType: (addType: string) => void;
    addType: String,
    getPatientDataAll: any,
    setPatientDataId: any
    patientDataId: any
}

const ClinicalNotesModalAdd: React.FC<EditVitalsProps> = (props) => {
    const { addType, setAddType } = props;
    const { t } = useTranslation();
    let navigate = useNavigate();
    const formRef = useRef<any>();
    const [compliant, editCompliant] = useState('');
    const [observations, editObservations] = useState('');
    const [notes, editNotes] = useState('');
    return (



        <BaseModal getContainer={false} size={'large'} {...props} open={addType == '2'} onCancel={() => setAddType('')} onOk={() => setAddType('')} footer={<></>} >

            <h3 style={{ marginBottom: '20px', borderBottom: '1px solid grey' }}>Add New Clinical Notes</h3>

            <ValidationFormNotesAdd
                formRef={formRef}
                compliant={compliant}
                editCompliant={editCompliant}
                observations={observations}
                editObservations={editObservations}
                notes={notes}
                editNotes={editNotes}

                setAddType={setAddType}
                getPatientDataAll={props.getPatientDataAll}
                setPatientDataId={props.setPatientDataId}
                patientDataId={props.patientDataId}
            />
        </BaseModal>

    );
};

export default ClinicalNotesModalAdd;
