import config from "@app/config";
import axios from "@app/services/axios";




export const login = (loginPayload: any): Promise<any> =>
    axios.post<any>('/users/login', { ...loginPayload }).then((res: any) => {


        return res.data;

    });


export const sendPasswordOTP = (payload: any): Promise<any> =>
    axios.post<any>('/users/sendResetPasswordOTP', { ...payload }).then((res: any) => {


        return res.data;

    });

export const changePasswordViaOtp = (payload: any): Promise<any> =>
    axios.post<any>('/users/changepassword', { ...payload }).then((res: any) => {


        return res.data;

    });



export const createInternalUser = (createUserPayload: any): Promise<any> =>
    axios.post<any>('/users', { ...createUserPayload }).then((res: any) => {


        return res.data;

    });



export const updateInternalUser = (updateUserPayload: any): Promise<any> =>
    axios.put<any>('/users/' + updateUserPayload.id, { ...updateUserPayload }).then((res: any) => {


        return res.data;

    });


export const updateExternalUserOtherInfo = (updateUserPayload: any): Promise<any> =>
    axios.put<any>('/users/externalUserOtherInfo/' + updateUserPayload.id, { ...updateUserPayload }).then((res: any) => {


        return res.data;

    });

export const getInternalUserById = (id: number): Promise<any> =>
    axios.get<any>('/users/' + id).then((res: any) => {


        return res.data;

    });


export const getAllInternalUsers = (id: number): Promise<any> =>
    axios.get<any>('/users?type=' + id).then((res: any) => {


        return res.data;

    });

export const getAllClinics = (): Promise<any> =>
    axios.get<any>('/users/clinics').then((res: any) => {


        return res.data;

    });

export const getAllDoctors = (clinicId: number): Promise<any> =>
    axios.get<any>('/users/doctors/' + clinicId).then((res: any) => {


        return res.data;

    });

export const getAllClients = (clinicId: number): Promise<any> =>
    axios.get<any>('/users/clients/' + clinicId).then((res: any) => {


        return res.data;

    });


export const getAllSpecialities = (): Promise<any> =>
    axios.get<any>('/specialities').then((res: any) => {


        return res.data;

    });

export const getStatesTree = (): Promise<any> =>
    axios.get<any>('/areas/states').then((res: any) => {


        return res.data;

    });


export const getAllFacilities = (): Promise<any> =>
    axios.get<any>('/miscellaneous/facility').then((res: any) => {


        return res.data;

    });

export const getFacilityById = (id: number): Promise<any> =>
    axios.get<any>('/miscellaneous/facility/' + id).then((res: any) => {


        return res.data;

    });

export const getMinimalClinicDetailById = (id: number): Promise<any> =>
    axios.get<any>('/miscellaneous/minimalclinic/' + id).then((res: any) => {


        return res.data;

    });

export const saveFacility = (createUserPayload: any): Promise<any> =>
    axios.post<any>('/miscellaneous/facility', { ...createUserPayload }).then((res: any) => {


        return res.data;

    });



export const updateFacility = (updateUserPayload: any): Promise<any> =>
    axios.put<any>('/miscellaneous/facility/' + updateUserPayload.id, { ...updateUserPayload }).then((res: any) => {


        return res.data;

    });





/** medicines */

export const getAllMedicines = (): Promise<any> =>
    axios.get<any>('/miscellaneous/medicines').then((res: any) => {


        return res.data;

    });

export const getMedicineById = (id: number): Promise<any> =>
    axios.get<any>('/miscellaneous/medicineById/' + id).then((res: any) => {


        return res.data;

    });



export const saveMedicine = (createUserPayload: any): Promise<any> =>
    axios.post<any>('/miscellaneous/medicines', { ...createUserPayload }).then((res: any) => {


        return res.data;

    });



export const updateMedicine = (updateUserPayload: any): Promise<any> =>
    axios.put<any>('/miscellaneous/medicines/' + updateUserPayload.id, { ...updateUserPayload }).then((res: any) => {


        return res.data;

    });
//-----------   end





/** medicines */

export const getAllLabTests = (): Promise<any> =>
    axios.get<any>('/miscellaneous/labtests').then((res: any) => {


        return res.data;

    });

export const getLabTestById = (id: number): Promise<any> =>
    axios.get<any>('/miscellaneous/labtestById/' + id).then((res: any) => {


        return res.data;

    });



export const saveLabTest = (createUserPayload: any): Promise<any> =>
    axios.post<any>('/miscellaneous/labtests', { ...createUserPayload }).then((res: any) => {


        return res.data;

    });



export const updateLabTest = (updateUserPayload: any): Promise<any> =>
    axios.put<any>('/miscellaneous/labtests/' + updateUserPayload.id, { ...updateUserPayload }).then((res: any) => {


        return res.data;

    });








export const getOtherInfoById = (id: number): Promise<any> =>
    axios.get<any>('/miscellaneous/otherinfo/' + id).then((res: any) => {


        return res.data;

    });



export const saveOtherInfo = (createUserPayload: any): Promise<any> =>
    axios.post<any>('/miscellaneous/otherinfo', { ...createUserPayload }).then((res: any) => {


        return res.data;

    });



export const updateOtherInfo = (updateUserPayload: any): Promise<any> =>
    axios.put<any>('/miscellaneous/otherinfo/' + updateUserPayload.id, { ...updateUserPayload }).then((res: any) => {


        return res.data;

    });





/** testimonials */

export const getAllTestimonials = (): Promise<any> =>
    axios.get<any>('/miscellaneous/testimonial').then((res: any) => {


        return res.data;

    });

export const getTestimonialsById = (id: number): Promise<any> =>
    axios.get<any>('/miscellaneous/testimonial/' + id).then((res: any) => {


        return res.data;

    });



export const saveTestimonials = (createUserPayload: any): Promise<any> =>
    axios.post<any>('/miscellaneous/testimonial', { ...createUserPayload }).then((res: any) => {


        return res.data;

    });



export const updateTestimonials = (updateUserPayload: any): Promise<any> =>
    axios.put<any>('/miscellaneous/testimonial/' + updateUserPayload.id, { ...updateUserPayload }).then((res: any) => {


        return res.data;

    });



export const SendInvitationEmails = (payload: any): Promise<any> =>
    axios.post<any>('/miscellaneous/sendBulkInvitations', { ...payload }).then((res: any) => {


        return res.data;

    });




export const getAdminDashboardInfo = (): Promise<any> =>
    axios.get<any>('/miscellaneous/adminDashboardInfo').then((res: any) => {


        return res.data;

    });

export const getClinicDashboardInfo = (id: number): Promise<any> =>
    axios.get<any>('/miscellaneous/clinicDashboardInfo/' + id).then((res: any) => {


        return res.data;

    });


export const getAvailabilitySlotsOfDocInfo = (id: number): Promise<any> =>
    axios.get<any>('/miscellaneous/availability/' + id).then((res: any) => {


        return res.data;

    });


export const updateAvailabilityClinic = (updateUserPayload: any): Promise<any> =>
    axios.put<any>('/miscellaneous/availability/' + updateUserPayload.clinicId, { ...updateUserPayload }).then((res: any) => {


        return res.data;

    });
